import React, { Fragment, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { NumericFormat } from 'react-number-format';
import Pagination from "../../../@yb/components/Pagination/Pagination";
import moment from "moment";
import "moment/locale/fr";
import { useSelector } from "react-redux";
import notification from "../../../@yb/components/notification";
import { Link, useNavigate } from "react-router-dom";
import Table from "../../../@yb/components/Table/Table";
import {HiEye} from "react-icons/hi";
import {Badge, Flex, Icon} from "@tremor/react";
import Constants from "../../../@yb/utils/constants";
import getStatusBadge from "../../../@yb/utils/getStatusBadge";

moment.locale("fr");

function PaymentList(props) {
  const [payments, setPayments] = useState({ data: [], meta: {} });
  const [postsPerPage] = useState(5);
  const { selectedAccount } = useSelector((state) => state.account);
  const { active } = useSelector((state) => state.invoice);

  const public_key = selectedAccount.is_live ? selectedAccount.pk_live : selectedAccount.pk_test;

  const getPayments = async (page = 1) => {
    let url;
    if (props.valueFromParent) {
      url = `${Constants.getPaymentUrl(selectedAccount)}/api/v1/payment-intents/account/${public_key}?page=${page}&perPage=${postsPerPage}&status=${props.valueFromParent}`;
    } else if (props.paymentLink) {
      url = `${Constants.getPaymentUrl(selectedAccount)}/api/v1/payment-intents/account/${public_key}?page=${page}&perPage=${postsPerPage}&payment_link_id=${props.paymentLink}`;
    } else if (props.origine) {
      url = `${Constants.getPaymentUrl(selectedAccount)}/api/v1/payment-intents/account/${public_key}?page=${page}&perPage=${postsPerPage}&origine=${props.origine}`;
    } else {
      url = `${Constants.getPaymentUrl(selectedAccount)}/api/v1/payment-intents/account/${public_key}?page=${page}&perPage=${postsPerPage}`;
    }
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(function (response) {
        setPayments(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  useEffect(() => {
    getPayments();
  }, [selectedAccount, active]);

  const columns = useMemo(
    () => [
      {
        header: "Montant",
        accessor: "amount",
        render: (price) => (
          <NumericFormat
            value={price}
            displayType={"text"}
            thousandSeparator={true}
            prefix={`${selectedAccount.country.currency.code ?? "EUR"}`.toUpperCase()}
          />
        ),
      },
      {
        header: "Status",
        accessor: "status",
        render: (status) => {
          return getStatusBadge(status)
        },
      },
      {
        header: "Payout",
        accessor: "payout_status",
        render: (payout_status) => {
          return getStatusBadge(payout_status)
        }
      },
      {
        header: "Payment ID",
        accessor: "intent_id",
      },
      {
        header: "Origine",
        accessor: "origine"
      },
      {
        header: "Client",
        accessor: "customer",
        render: (customer) => customer?.name
      },
      {
        header: "Date",
        accessor: "created_at",
        render: (date) => moment(date).format("LL"),
      },
    ],
    []
  );

  function MyDropdown(item) {
    return (
      <>
        <Flex
          alignItems="items-center"
          justifyContent="justify-end"
          spaceX='space-x-2'
        >
        <Link className="cursor-pointer"  to={`/payment/${item.intent_id}`}>
          <Icon
            icon={HiEye}
            variant="solid"
            tooltip="Show"
            size="xs"
            color="indigo"
            marginTop="mt-0"
          />
        </Link>
        </Flex>

      </>
    );
  }

  return (
    <div className="h-full w-full mt-8">
      {payments.data?.length ? (
          <Table
            data={payments?.data}
            columns={columns}
            onActionClick={MyDropdown}
            pagination={
              <Pagination
                onPageChange={getPayments}
                meta={payments?.meta}
              />
            }
          />
      ) : (
        <div className="h-full flex items-center justify-center">
          <div className="flex-col w-1/3 mt-8">
            <div className="pb-5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-12 w-12 p-1 rounded bg-gray-200 text-gray-500"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="font-bold">Aucun paiement test</div>
            <div className="text-gray-500 font-medium">
              Pour démarrer rapidement,
              <span className="text-indigo-500">créez un paiement</span> ou
              <span className="text-indigo-500"> envoyez une facture</span>{" "}
              manuellement.
            </div>
            <a
              href="src/components/payments"
              className="font-medium flex items-center text-indigo-500"
            >
              <span className="mr-2">En savoir plus</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14 5l7 7m0 0l-7 7m7-7H3"
                />
              </svg>
            </a>
            <div className="flex mt-1">
              <a
                href="/payment/new"
                className="flex items-center rounded-lg bg-indigo-500 px-2 py-1.5 text-white font-medium"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                  />
                </svg>
                Créer un paiement
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PaymentList;
