import React from "react";
import { MdOutlineClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { resetOneINvoice } from "../../redux/features/invoices/crud/invoiceSlice";
import { setRemoveCustomer } from "../../redux/features/customer/customerSlice";
import { setRemoveMode } from "../../redux/features/invoices/invoiceModeSlice";
import { setRemoveProduct } from "../../redux/features/product/productSlice";
import {
  removeToBasket,
  removeToUpdateBasket,
} from "../../redux/features/invoices/billSlice";
import { createNewInvoice } from "../../redux/features/invoices/crud/invoiceSlice";
import { resetCustomerInput } from "../../redux/features/customer/customerInputSlice";
import { resetCreatePaymentLink } from "../../redux/features/payment/createPaymentLinkSlice";
import { updatedInvoice } from "../../redux/features/invoices/crud/updateInvoiceSlice";
import moment from "moment";

const Topbard = ({ isEdit, updateBase }) => {
  const dispatch = useDispatch();
  const { account, invoice, invoiceMode, updateInvoice } = useSelector(
    (state) => state
  );
  const id = account?.selectedAccount?.id;
  const { limitDate } = invoiceMode?.limitDate !== null && invoiceMode;

  const { cartProducts } = useSelector((state) => state.paymentLink);
  const { customer } = useSelector((state) => state.customerInput);

  const navigate = useNavigate();

  const reset = () => {
    dispatch(setRemoveCustomer());
    dispatch(setRemoveMode());
    dispatch(setRemoveProduct());
    dispatch(removeToBasket());
    dispatch(removeToUpdateBasket());
    dispatch(resetOneINvoice());
    dispatch(resetCustomerInput());
    dispatch(resetCreatePaymentLink());
    dispatch(resetCustomerInput());
  };

  const createInvoice = (e) => {
    e.preventDefault();

    const invoiceData = {
      accountId: id,
      dueDate: moment(limitDate).format("YYYY-MM-DD"),
      customer: customer?.id,
      cartProducts: cartProducts,
    };

    // console.log(invoiceData);
    dispatch(createNewInvoice({ navigate, invoiceData })).then((res) => {
      if (res?.meta?.requestStatus === "fulfilled") {
        reset();
        navigate("/payment");
      }
    });
  };

  const onUpdateInvoice = () => {
    const invoiceID = updateBase?.invoiceId;

    const updateInvoiceDate = {
      accountId: id,
      customer: customer?.id,
      dueDate: moment(limitDate).format("YYYY-MM-DD"),
      cartProducts: updateBase?.cartProducts,
    };

    dispatch(updatedInvoice({ updateInvoiceDate, invoiceID })).then((res) => {
      if (res?.meta?.requestStatus === "fulfilled") {
        navigate("/payment");
      }
    });
  };

  return (
    <>
      <div className="border border-b h-16 w-full flex justify-between items-center px-6 fixed bg-white">
        <div className="flex">
          <Link to="/payment">
            <MdOutlineClose className="h-6 w-6 text-gray-600" />
          </Link>
          <div className="border border-l h-6 w-0 ml-5"></div>
          {isEdit ? (
            <span className="ml-5">Update invoice</span>
          ) : (
            <span className="ml-5">Create invoice</span>
          )}
        </div>
        <div className="flex gap-2 ">
          <button
            type="submit"
            className="flex items-center justify-center text-center  bg-red-500  text-white py-1 px-3 font-medium rounded-lg"
            onClick={reset}
          >
            Réinitialiser
          </button>
          {isEdit ? (
            <button
              type="submit"
              className="flex items-center justify-center text-center w-full bg-green-600
              text-white py-1 px-3 font-medium rounded-lg"
              onClick={onUpdateInvoice}
              disabled={updateInvoice?.loading}
            >
              {updateInvoice?.loading ? (
                <div className="text-center w-36 flex gap-2 justify-center items-center">
                  <svg
                    className="h-5 w-5 text-center animate-spin text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                  updating...
                </div>
              ) : (
                "Mettre à jour"
              )}
            </button>
          ) : (
            <button
              type="submit"
              className="flex items-center justify-center text-center w-full  bg-blue-700 text-white py-1 px-3 font-medium rounded-lg"
              onClick={createInvoice}
              disabled={invoice?.loading}
            >
              {invoice?.loading ? (
                <div className="text-center w-24 flex gap-2 justify-center items-center">
                  <svg
                    className="h-5 w-5 text-center animate-spin text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                  creating...{" "}
                </div>
              ) : (
                "Enregistrer"
              )}
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default Topbard;
