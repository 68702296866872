import React from 'react';
import PaymentTapType1 from "./paymentTapType1";
import {useDispatch, useSelector} from "react-redux";
import PaymentTapType2 from "./paymentTapType2";
import {setCallToAction, setCollectUserDetails} from "../../../../redux/features/payment/createPaymentLinkSlice";

function PaymentTab() {

  const {selectedType, collectUserDetails, callToAction} = useSelector(state => state.paymentLink)
  const dispatch = useDispatch()

  return (
    <>
      {selectedType.type === 1 ? <PaymentTapType1/> : <PaymentTapType2 />}

      <hr/>

      <span className="font-bold text-2xl">Options</span>

      <div className="flex items-center mb-4 mt-5">
        <input id="default-checkbox" type="checkbox" checked={collectUserDetails} value={collectUserDetails}
               onChange={(event) => {
                 dispatch(setCollectUserDetails(event.target.checked))
               }} className="w-4 h-4 shadow-sm border border-gray-200 rounded ring-0"/>
        <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium ">Collect customer's address</label>
      </div>

      <div className="flex flex-row items-center">
        <select value={callToAction} onChange={(event) => {
          dispatch(setCallToAction(event.target.value))
        }}
          className="focus:outline-0 shadow-md block text-xs pr-12 border-gray-300 rounded-lg border"
        >
          <option value="Pay">Pay</option>
          <option value="Donate">Donate</option>
          <option value="Book">Book</option>
        </select>
        <span className="ml-2">as the call to action</span>
      </div>


    </>
  );
}

export default PaymentTab;