import React from 'react';
import {useNavigate} from "react-router-dom";

function ProductEmpty(props) {

  const navigate = useNavigate();


  return (
    <div className='h-full mt-20'>
      <div className="h-full flex items-center justify-center">
        <div className="flex-col w-1/3">
          <div className="pb-5">
            <svg aria-hidden="true"
                 className="h-12 w-12 p-3 rounded bg-gray-200 text-gray-500" fill="currentColor"
                 height="36" width="36" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13.788 3.119a1.04 1.04 0 0 1-.31.283L8.5 6.362a.97.97 0 0 1-.998 0l-4.98-2.96a1.04 1.04 0 0 1-.309-.283L6.99.279a1.97 1.97 0 0 1 2.02 0zm1.194 1.647c.012.09.018.182.018.274v5.92c0 .743-.385 1.43-1.01 1.802l-4.98 2.96a1.97 1.97 0 0 1-2.02 0l-4.98-2.96A2.092 2.092 0 0 1 1 10.96V5.04c0-.092.006-.184.018-.274.147.133.308.252.481.355l4.98 2.96a2.97 2.97 0 0 0 3.042 0l4.98-2.96c.173-.103.334-.222.481-.355z"
                fillRule="evenodd"></path>
            </svg>
          </div>
          <div className="font-bold">Add your first test product</div>
          <div className="text-gray-500 font-medium">
            Products are what you sell to customers. They can be anything from physical goods to digital services or subscription plans.
          </div>
          <a href="" className="font-medium flex items-center text-indigo-500">
            <span className="mr-2">En savoir plus</span>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M14 5l7 7m0 0l-7 7m7-7H3"/>
            </svg>
          </a>
          <button onClick={() => navigate('/product/create')}
            className="flex items-center rounded-lg bg-indigo-500 px-3 py-1.5 mt-4 text-white font-medium">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none"
                 viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6m0 0v6m0-6h6m-6 0H6"/>
            </svg>
            Ajouter un produit
          </button>
        </div>
      </div>
    </div>
  );
}

export default ProductEmpty;