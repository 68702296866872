import React from 'react';

function EmptyPayment(props) {
  return (
    <div className="h-full flex items-center justify-center">
      <div className="flex-col w-1/3">
        <div className="pb-5">
          <svg xmlns="http://www.w3.org/2000/svg"
               className="h-12 w-12 p-1 rounded bg-gray-200 text-gray-500" viewBox="0 0 20 20"
               fill="currentColor">
            <path fillRule="evenodd"
                  d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z"
                  clipRule="evenodd"/>
          </svg>
        </div>
        <div className="font-bold">Aucun paiement test</div>
        <div className="text-gray-500 font-medium">
          Pour démarrer rapidement,<span className="text-indigo-500">créez un paiement</span> ou
          <span className="text-indigo-500"> envoyez une facture</span> manuellement.
        </div>
        <a href="" className="font-medium flex items-center text-indigo-500">
          <span className="mr-2">En savoir plus</span>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24"
               stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M14 5l7 7m0 0l-7 7m7-7H3"/>
          </svg>
        </a>
        <button
          className="flex items-center rounded-lg bg-indigo-500 px-3 py-1.5 mt-4 text-white font-medium">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none"
               viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6m0 0v6m0-6h6m-6 0H6"/>
          </svg>
          Créer un paiement
        </button>
      </div>
    </div>
  );
}

export default EmptyPayment;