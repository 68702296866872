import React, { useState } from 'react';
import Payment from "../../components/balance/payment";
import Overview from "../../components/balance/overview";
import Recharge from "../../components/balance/recharge";

function getChildren(path) {
    switch (path) {
        case '/':
            return <Overview />;
        case '/1':
            return <Recharge />;
        case '/2':
            return <Payment />;
        default:
            return <div>404</div>;
    }
}

function Balance() {
    const [currentPath, setCurrentPath] = useState("/1");
    return (
        <div className="h-full py-10 flex">
            <div className="w-60">
                <h3 className="mb-4 text-black font-bold text-xl">Balance</h3>
                <div className="border-l border-gray-200">
                    <ul>
                        {/* <li onClick={() => setCurrentPath("/")}
                            className={`${currentPath === "/" ? 'border-indigo-500 text-indigo-500 font-bold border-l-2' : 'text-gray-500  hover:text-black '} cursor-pointer border-transparent transition ease-in-out duration-150 px-3 py-1 text-sm leading-5`}>
                            <a>Overview</a>
                        </li> */}
                        <li onClick={() => setCurrentPath("/1")}
                            className={`${currentPath === "/1" ? 'border-indigo-500 text-indigo-500 font-bold border-l-2' : 'text-gray-500  hover:text-black'} cursor-pointer  border-transparent transition ease-in-out duration-150 px-3 py-1 text-sm leading-5 mt-2`}>
                            <a>Recharge</a>
                        </li>
                        <li onClick={() => setCurrentPath("/2")}
                            className={`${currentPath === "/2" ? 'border-indigo-500 text-indigo-500 font-bold border-l-2' : 'text-gray-500  hover:text-black'} cursor-pointer  border-transparent transition ease-in-out duration-150 px-3 py-1 text-sm leading-5 mt-2`}>
                            <a>Payment</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="w-full">
                {getChildren(currentPath)}
            </div>
        </div>
    );
}

export default Balance;