import React, {useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import notification from "../../../@yb/components/notification";
import {useSelector} from "react-redux";
import moment from "moment";
import Payments from "../payment/payments";
import {getPaymentLink} from "../../../services/api/paymentLinkServiceApi";


function PaymentLinkDetails(props) {

  const [payment, setPaymentLink] = React.useState({});
  const {selectedAccount} = useSelector(state => state.account);

  const {id} = useParams();

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    try {
      const paymentLink = await getPaymentLink(id, selectedAccount);
      setPaymentLink(paymentLink);
    } catch (e) {
      console.log(e);
      notification.error("Une erreur s'est produite lors de la récupération des détails du lien de paiement");
    }
  }


  return (
    <div className="flex flex-col mt-6">
      <div className="mb-5 w-32">
        <Link to="/payment"
              className="flex items-center rounded-lg border px-2 py-1.5 bg-white text-sm font-medium">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 pr-1" fill="none"
               viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M10 19l-7-7m0 0l7-7m-7 7h18"/>
          </svg>
          Retour
        </Link>
      </div>
      <div className="flex flex-row mt-4 mb-10 text-gray-600">
        <div className="flex flex-col pr-4 border-r">
          <span>Date</span>
          <span>{moment(payment.created_at).format("LLL")}</span>
        </div>
        <div className="flex flex-col px-4">
          <span>Titre</span>
          <div className="flex flex-row items-center">
            <span className="mr-2">{payment.title}</span>
          </div>
        </div>
      </div>
      <hr/>
      <div className="flex flex-col mb-8 mt-8">
        <div className="font-bold text-xl mb-2">Paiements associés</div>
        <hr/>
        <div className="flex flex-col items-center">
          <Payments paymentLink={id} />
        </div>
      </div>
    </div>
  );
}

export default PaymentLinkDetails;