import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getProducts = createAsyncThunk(
  "product/getProducts",
  async (id) => {
    return axios
      .get(`${process.env.REACT_APP_STORE_URL}/account/${id}/products`, {
        headers: {
          "Authorization-token": "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }
);

const productSlice = createSlice({
  name: "product",
  initialState: {
    products: [],
    loading: false,
    error: null,
    active: ".0",
    selectedProduct: null,
    basketProducts: [{}],
  },
  reducers: {
    setProductActive: (state, action) => {
      state.active = action.payload;
    },
    setProducts(state, action) {
      state.products = action.payload;
    },
    setSelectedProduct: (state, action) => {
      state.selectedProduct = action.payload;
      state.basketProducts = [state.selectedProduct];
    },
    setRemoveProduct: (state, action) => {
      state.selectedProduct = action.payload;
      state.basketProducts = null;
    },
  },
  extraReducers: {
    [getProducts.pending]: (state, action) => {
      state.isloading = true;
    },
    [getProducts.fulfilled]: (state, action) => {
      state.isloading = false;
      state.products = action.payload;
    },
    [getProducts.rejected]: (state, action) => {
      state.isloading = false;
      state.error = action.payload;
    },
  },
});

export const {
  setProductActive,
  setProducts,
  setSelectedProduct,
  setRemoveProduct,
} = productSlice.actions;
export default productSlice.reducer;
