import React, { Fragment, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import axios from "axios";
import notification from "../../@yb/components/notification";
import Pagination from "../../@yb/components/Pagination/Pagination";
import { useSelector } from "react-redux";
import Table from "../../@yb/components/Table/Table";
import { Menu, Transition } from "@headlessui/react";
import { HiDotsHorizontal } from "react-icons/hi";

function Overview() {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(5);
  const [customers, setCustomers] = useState({});
  const [checked, setChecked] = useState(true);
  const [checkedLiv, setCheckedLiv] = useState(true);
  const [country, setCountry] = useState([]);
  const [displayCustomerAdd, setDisplayCustomerAdd] = useState(false);
  const { accounts, selectedAccount } = useSelector((state) => state.account);

  const postCustomerObject = {
    name: null,
    email: null,
    accountId: selectedAccount.id,
    description: "",
    billingEmail: "",
    billingAddress: "",
    billingPhone: "",
    billingCountryId: "",
    shippingCountryId: "",
    shippingAddress: "",
    shippingName: "",
    shippingPhone: "",
    language: "",
    currencyId: "",
  };
  const [postCustomer, setPostCustomer] = useState(postCustomerObject);

  useEffect(() => {
    getCustomers();
  }, []);

  useEffect(() => {
    getCountry();
  }, []);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  // const currentPosts = customers.data.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginateFront = () => {
    if (customers?.meta?.next_page_url) {
      setCurrentPage(currentPage + 1);
      getCustomers(currentPage + 1);
    } else {
      notification.success("Vous êtes à la dernière page");
    }
  };
  const paginateBack = () => {
    if (customers?.meta?.previous_page_url) {
      setCurrentPage(currentPage - 1);
      getCustomers(currentPage - 1);
    } else {
      notification.success("Vous êtes déjà sur la première page");
    }
  };

  const getCustomers = async (page = 1) => {
    axios
      .get(
        `${process.env.REACT_APP_AUTH_URL}/v1/account/${selectedAccount.id}/clients?page=${page}&perPage=${postsPerPage}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(function (response) {
        setCustomers(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  async function addCustomer() {
    axios
      .post(`${process.env.REACT_APP_AUTH_URL}/v1/client`, postCustomer, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(function (response) {
        getCustomers();
        setDisplayCustomerAdd(false);
      });
  }

  const getCountry = async () => {
    axios
      .get(`https://auth.api.yabetoopay.com/api/v1/countries`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(function (response) {
        setCountry(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const columns = useMemo(
    () => [
      { header: "Nom", accessor: "name" },
      { header: "Email", accessor: "email" },
      {
        header: "Date de création",
        accessor: "created_at",
        render: (row) => moment(row).format("LL"),
      },
    ],
    []
  );

  function MyDropdown(items) {

    const links = [
      { href: '/account-settings', label: 'Account settings' },
      { href: '/support', label: 'Support' },
      { href: '/license', label: 'License' },
      { href: '/sign-out', label: 'Sign out' },
    ]

    return (
      <>
        <Menu as="div" className="">
          <Menu.Button >
            <HiDotsHorizontal className="h-5 w-5 text-gray-400" />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="bg-white z-10 absolute right-8 my-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md  shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              {links.map((link) => (
                /* Use the `active` state to conditionally style the active item. */
                <div className="px-1 py-1 ">
                  <Menu.Item key={link.href} as={Fragment}>
                    {({ active }) => (
                      <a
                        href={link.href}
                        className={`${
                          active ? "bg-violet-500 text-white" : "text-gray-900"
                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      >
                        {link.label}
                      </a>
                    )}
                  </Menu.Item>
                </div>
              ))}
            </Menu.Items>
          </Transition>
        </Menu>


      </>
    );
  }

  return (
    <>
      <div className="h-full">
        <div className="flex flex-row items-center mb-4">
          <h3 className="flex-1 text-black font-bold text-3xl">Customers</h3>
          {/*<div className="rounded-lg px-2 py-1.5 mr-3 font-medium border bg-white cursor-pointer">
            Export
          </div>
          <div
            className="flex items-center rounded-lg bg-indigo-500 px-2 py-1.5 text-white font-medium cursor-pointer"
            onClick={() => {
              setDisplayCustomerAdd(true);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 mr-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
              />
            </svg>
            Add customer
          </div>*/}
        </div>

        <Table
          data={customers?.data ?? []}
          columns={columns}
          pagination={
            <Pagination
              meta={customers?.meta}
              onPageChange={getCustomers()}
            />
          }
         /* onActionClick={(i) => MyDropdown(i)}*/
        />

        {displayCustomerAdd && (
          <>
            <div
              onClick={() => {
                setDisplayCustomerAdd(false);
              }}
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                top: 0,
                left: 0,
                right: 0,
                height: "100%",
                position: "absolute",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            ></div>
            <div className="bg-white absolute top-36 left-0 right-0 ml-auto mr-auto h-3/4 w-4/12 pt-4 pb-4 pl-8 rounded-xl">
              <div className="mb-4 font-bold">Ajouter un client</div>
              <div className="bg-white h-5/6 overflow-y-scroll">
                <div className="mr-8">
                  <div className="font-semibold">
                    Informations sur le compte
                  </div>
                  <div className="mt-4">Nom</div>
                  <input
                    required
                    onChange={(e) =>
                      setPostCustomer({ ...postCustomer, name: e.target.value })
                    }
                    className="mt-2 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                    placeholder="julie dupont"
                  />
                  <div className="mt-4">Adresse e-mail du compte</div>
                  <input
                    required
                    onChange={(e) =>
                      setPostCustomer({
                        ...postCustomer,
                        email: e.target.value,
                      })
                    }
                    className="mt-2 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                    placeholder="you@example.com"
                  />
                  <div className="mt-4">Description</div>
                  <input
                    onChange={(e) =>
                      setPostCustomer({
                        ...postCustomer,
                        description: e.target.value,
                      })
                    }
                    className="mt-2 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                    placeholder=""
                  />
                  <div className="mt-4 font-semibold">
                    Informations facturation
                  </div>
                  <div className="mt-4">Adresse e-mail de facturation</div>
                  <div className="flex mt-2 items-center">
                    <input
                      defaultChecked={checked}
                      onChange={() => setChecked(!checked)}
                      type="checkbox"
                      className="checked:bg-blue-500"
                    />
                    <div>Identique à l'adresse email du compte</div>
                  </div>
                  {!checked && (
                    <input
                      className="px-3 py-2 mt-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                      placeholder="you@example.com"
                    />
                  )}
                  <div className="mt-4">Adresse de facturation</div>
                  <select
                    className="mt-2"
                    onChange={(e) => {
                      console.log(e);
                    }}
                  >
                    <option value="-1">Choisir un pays</option>
                    {country.map((it, index) => (
                      <option value={index}>{it.name}</option>
                    ))}
                  </select>
                  <div className="mt-4">Numéro de téléphone</div>
                  <input
                    className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                    placeholder="+33 00 00 00 00"
                  />
                  <div className="mt-4">Adresse de livraison</div>
                  <div className="flex mt-2 items-center">
                    <input
                      defaultChecked={checkedLiv}
                      onChange={() => setCheckedLiv(!checkedLiv)}
                      type="checkbox"
                      className="checked:bg-blue-500"
                    />
                    <div>Identique aux données de facturation</div>
                  </div>
                  {!checkedLiv && (
                    <>
                      <input
                        className="px-3 py-2 mt-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                        placeholder="julie dupont"
                      />
                      <select
                        className="mt-2"
                        onChange={(e) => {
                          console.log(e);
                        }}
                      >
                        <option value="-1">Choisir un pays</option>
                        {country.map((it, index) => (
                          <option value={index}>{it.name}</option>
                        ))}
                      </select>
                      <input
                        className="mt-2 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                        placeholder="+33 00 00 00 00"
                      />
                    </>
                  )}
                  <div className="mt-4">Fuseau horaire</div>
                  <select
                    className="mt-2"
                    onChange={(e) => {
                      console.log(e);
                    }}
                  >
                    <option value="-1">Choisir un fuseau horaire</option>
                    {country.map((it, index) => (
                      <option value={index}>{it.name}</option>
                    ))}
                  </select>
                  <div className="mt-4">Devise</div>
                  <select
                    className="mt-2"
                    onChange={(e) => {
                      console.log(e);
                    }}
                  >
                    <option value="-1">Choisir une devise</option>
                    {country.map((it, index) => (
                      <option value={index}>{it.currency.name}</option>
                    ))}
                  </select>
                  <div className="mt-4">Statut fiscal</div>
                  <select
                    onChange={(e) => {
                      console.log(e);
                    }}
                  >
                    <option value="0">Imposable</option>
                    <option value="1">Exonéré</option>
                    <option value="2">Autoliquidation de la TVA</option>
                  </select>
                  <div className="mt-4">Numéro fiscal</div>
                  <div className="flex items-center mt-4 mb-8">
                    <div className="w-52">type identifiant</div>
                    <input
                      className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>

              <div className="absolute bottom-2 flex right-8">
                <div
                  onClick={() => {
                    setDisplayCustomerAdd(false);
                  }}
                  className="mx-2 p-1 rounded border-gray-400 border cursor-pointer"
                >
                  Cancel
                </div>
                <button
                  type="submit"
                  className="flex items-center rounded-lg bg-indigo-500 px-4 py-2 mt-4 text-white"
                  onClick={() => {
                    addCustomer();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 mr-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                    />
                  </svg>
                  Ajouter un client
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Overview;
