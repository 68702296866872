import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import notification from "../../../../@yb/components/notification";

export const getInvoieByShopId = createAsyncThunk(
  "invoice/getInvoieByShopId",
  async (id) => {
    return axios
      .get(`${process.env.REACT_APP_YABETOO_INVOICE}/invoice/account/${id}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }
);

export const getInvoieById = createAsyncThunk(
  "invoice/getInvoieById",
  async (id) => {
    return axios
      .get(`${process.env.REACT_APP_YABETOO_INVOICE}/invoice/${id}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }
);

export const createNewInvoice = createAsyncThunk(
  "invoice/createNewInvoice",
  async ({ invoiceData }) => {
    return axios
      .post(`${process.env.REACT_APP_YABETOO_INVOICE}/invoice`, invoiceData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => res.data)
      .catch((e) => e.response);
  }
);

const invoiceSlice = createSlice({
  name: "invoice",
  initialState: {
    invoices: [],
    invoice: {
      items: [],
    },
    loading: false,
    error: null,
    active: ".0",
  },
  reducers: {
    setInvoiceActive: (state, action) => {
      state.active = action.payload;
    },
    resetOneINvoice: (state, action) => {
      state.invoice = null;
    },
    setInvoiceLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: {
    [getInvoieById.pending]: (state, action) => {
      state.loading = true;
    },
    [getInvoieById.fulfilled]: (state, action) => {
      state.loading = false;
      state.invoice = action.payload;
    },
    [getInvoieById.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getInvoieByShopId.pending]: (state, action) => {
      state.loading = true;
    },
    [getInvoieByShopId.fulfilled]: (state, action) => {
      state.loading = false;
      state.invoices = action.payload;
    },
    [getInvoieByShopId.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [createNewInvoice.pending]: (state, action) => {
      state.loading = true;
    },
    [createNewInvoice.fulfilled]: (state, action) => {
      state.loading = false;
      state.invoice = action.payload.data;
      notification.success(action?.payload?.message);
    },
    [createNewInvoice.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      notification.error(action?.payload?.message);
    },
  },
});

export const { setInvoiceActive, resetOneINvoice, setInvoiceLoading } =
  invoiceSlice.actions;
export default invoiceSlice.reducer;
