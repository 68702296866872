import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import notification from "../../../../@yb/components/notification";

export const cancelInvoice = createAsyncThunk(
  "invoice/cancelInvoice",
  async (invoiceId) => {
    const datas = {
      id: "null",
    };
    return axios
      .post(
        `${process.env.REACT_APP_YABETOO_INVOICE}/invoice/${invoiceId}/cancel`,
        datas,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data)
      .catch((e) => e.response);
  }
);

const cancelSlice = createSlice({
  name: "cancelInvoice",
  initialState: {
    invoice: null,
    loading: false,
    error: null,
  },

  extraReducers: {
    [cancelInvoice.pending]: (state, action) => {
      state.loading = true;
    },
    [cancelInvoice.fulfilled]: (state, action) => {
      state.loading = false;
      state.invoice = action?.payload?.data;
      notification.success(action?.payload?.message);
    },
    [cancelInvoice.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      notification.error(action?.payload?.message);
    },
  },
});

export default cancelSlice.reducer;
