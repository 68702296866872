import flags from 'react-phone-number-input/flags'


function getCountryFlag(country) {
  switch (country) {
    case 'FR':
      return flags.FR(country)
    case 'CG':
      return flags.CG(country)
    case 'CI':
      return flags.CI(country)
    case 'CD':
      return flags.CD(country)
    case 'CM':
      return flags.CM(country)
    case 'GA':
      return flags.GA(country)
    case 'GN':
      return flags.GN(country)
    case 'GQ':
      return flags.GQ(country)
    case 'GW':
      return flags.GW(country)
    case 'ML':
      return flags.ML(country)
    case 'NE':
      return flags.NE(country)
    case 'SN':
      return flags.SN(country)
    case 'TD':
      return flags.TD(country)
    case 'TG':
      return flags.TG(country)
    case 'BJ':
      return flags.BJ(country)
    case 'BF':
      return flags.BF(country)
    case 'BI':
      return flags.BI(country)
    case 'DJ':
      return flags.DJ(country)
    case 'ER':
      return flags.ER(country)
    case 'ET':
      return flags.ET(country)
    case 'KE':
      return flags.KE(country)
    case 'KM':
      return flags.KM(country)
    case 'MG':
      return flags.MG(country)
    case 'MU':
      return flags.MU(country)
    case 'MW':
      return flags.MW(country)
    case 'MZ':
      return flags.MZ(country)
    case 'RE':
      return flags.RE(country)
    case 'RW':
      return flags.RW(country)
    case 'SC':
      return flags.SC(country)
    case 'SO':
      return flags.SO(country)
    case 'TZ':
      return flags.TZ(country)
    case 'UG':
      return flags.UG(country)
    case 'YT':
      return flags.YT(country)
    case 'ZM':
      return flags.ZM(country)
    case 'ZW':
      return flags.ZW(country)
    case 'AO':
      return flags.AO(country)
    case 'BW':
      return flags.BW(country)
    case 'LS':
      return flags.LS(country)
    case 'NA':
      return flags.NA(country)
    case 'SZ':
      return flags.SZ(country)
    case 'ZA':
      return flags.ZA(country)
    case 'SH':
      return flags.SH(country)
    case 'AC':
      return flags.AC(country)
    case 'CX':
      return flags.CX(country)
    case 'CC':
      return flags.CC(country)
    case 'NF':
      return flags.NF(country)

  }
}

export default getCountryFlag