import React from 'react';

function ProductIcon(props) {
  return (
    <div
      className="border border-sm rounded-lg flex items-center justify-center border-gray-200 p-1 w-12">
      <svg aria-hidden="true"
           className="m-1 text-gray-500 p-1" fill='currentColor'
           height="26" width="26" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.788 3.119a1.04 1.04 0 0 1-.31.283L8.5 6.362a.97.97 0 0 1-.998 0l-4.98-2.96a1.04 1.04 0 0 1-.309-.283L6.99.279a1.97 1.97 0 0 1 2.02 0zm1.194 1.647c.012.09.018.182.018.274v5.92c0 .743-.385 1.43-1.01 1.802l-4.98 2.96a1.97 1.97 0 0 1-2.02 0l-4.98-2.96A2.092 2.092 0 0 1 1 10.96V5.04c0-.092.006-.184.018-.274.147.133.308.252.481.355l4.98 2.96a2.97 2.97 0 0 0 3.042 0l4.98-2.96c.173-.103.334-.222.481-.355z"
          fillRule="evenodd"></path>
      </svg>
    </div>
  );
}

export default ProductIcon;