import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { validateInput } from "../../utils/Validator";
import { Badge } from "@tremor/react";



const getSizeClass = (size) => {
  switch (size) {
    case "sm":
      return "h-10";
    case "xs":
      return "h-8";
    case "lg":
      return "h-14 text-lg";
    default:
      return "h-12 text-md";
  }
}

const InputField = ({ value, label, placeholder, validators, type, onChange, size = "sm", rows = 3, shadow = false, labelSize = "text-sm", optional }) => {
  const [error, setError] = useState(false);

  const handleChange = (event) => {
    const { value } = event.target;
    setError(validateInput(validators, value));
    onChange(value);
  };

  return (
    <div className="form-group w-full">
      {label &&

        <div className="flex items-center ">
          <label className={`${labelSize} block mb-2 font-medium text-gray-700 pt-3 pr-3`} htmlFor="app-input-field">{label}  </label>
          {optional &&  <Badge
            text="optional"
            color="gray"
            size="sm"
            icon={undefined}
            tooltip=""
            marginTop="mt-0" />}
        </div>


      }

      {type === 'textarea' ? (
        <textarea
          className={`${shadow ? 'shadow-sm border-gray-300' : 'border-gray-300'} h-12 w-full border border-gray-300 rounded-lg px-3 py-2 outline-0`}
          placeholder={placeholder}
          value={value}
          rows={rows}
          defaultValue={value}
          onChange={handleChange}
        />
      ) : (
        <input
          required
          type={type}
          value={value}
          className={`${getSizeClass(size)} ${labelSize} ${shadow ? 'shadow-sm border-gray-300 rounded' : 'border-gray-300 rounded'} border text-gray-900 w-full border rounded-lg  px-3  outline-0`}
          placeholder={placeholder}
          onChange={handleChange}
        />
      )}
      {error && <span className='text-red-600'>{error.message}</span>}
    </div>
  )
};

InputField.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  validators: PropTypes.array,
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  rows: PropTypes.number,
  shadow: PropTypes.bool,
  optional: PropTypes.bool,
};

InputField.defaultProps = {
  value: null,
  size: 'sm',
  label: '',
  placeholder: '',
  type: 'text',
  validators: [],
  rows: 3,
  shadow: false,
  optional: false,
};

export default InputField;