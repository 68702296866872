import {createSlice} from '@reduxjs/toolkit'

const globalSlice = createSlice({
    name: 'global',
    initialState: {
      isLoading: true,
      isLive: false,
      message: '',
      error: null,
      selectedCountry: null,
      selectedOperator: null,
      operatorList: [],
      country: {},
      operator: {},
      isInvoice: false,
      currency: 'eur',
    },
    reducers: {
      setLoading: (state, action) => {
        state.isLoading = action.payload
      },
      setMessage: (state, action) => {
        state.message = action.payload
      },
      setError: (state, action) => {
        state.error = action.payload
      },
      setSelectedCountry: (state, action) => {
        state.selectedCountry = action.payload
      },
      setSelectedOperator: (state, action) => {
        state.selectedOperator = action.payload
      },
      setOperatorList: (state, action) => {
        state.operatorList = action.payload
      },
      setLive: (state, action) => {
        state.isLive = action.payload
      },
      setCountry: (state, action) => {
        state.country = action.payload
      },
      setOperator: (state, action) => {
        state.operator = action.payload
      },
      setIsInvoice: (state, action) => {
        state.isInvoice = action.payload
      },
      setCurrency: (state, action) => {
        state.currency = action.payload
      }
    }
  }
)

export const {setLoading, setError, setMessage, setSelectedCountry, setSelectedOperator, setOperatorList, setLive, setCountry, setOperator, setIsInvoice, setCurrency} = globalSlice.actions

export default globalSlice.reducer