import React, {useState} from "react";
import Topbard from "../../components/invoices/Topbard";
import ProductInput from "../../@yb/components/invoices/ProductInput";
import InvoiceAddCustomer from "../../components/invoices/InvoiceAddCustomer";
import InvoiceDueDate from "../../components/invoices/InvoiceDueDate";

const Add = () => {
  const [step, setStep] = useState(1);

  const onNext = (value) => {
    setStep(value);
  }

  return (
    <>
      <main className="bg-white h-full">
        <Topbard isEdit={false}/>
        <div className="max-w-2xl mx-auto "></div>

        <div className="flex flex-row h-full w-full pt-16">
          {step === 1 ? <InvoiceAddCustomer onNext={onNext}/> : step === 2 ? <ProductInput onNext={onNext}/> :
            <InvoiceDueDate onNext={onNext}/>}
        </div>
      </main>
    </>
  );
};

export default Add;
