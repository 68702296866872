import React, {useState} from 'react';
import {useSelector} from "react-redux";
import ReactTooltip from "react-tooltip";

function ApiKey() {

    const {selectedAccount} = useSelector(state => state.account);
    const [clipboard, setClipboard] = useState("Click to copy");

    const pk = selectedAccount.is_live ? selectedAccount.pk_live : selectedAccount.pk_test;
    const sk = selectedAccount.is_live ? selectedAccount.sk_live : selectedAccount.sk_test;


    return (
        <div>
            <div className="border rounded">
                <div className="flex items-center justify-between p-5 border-b">
                    <div>
                        <h3 className="text-black font-bold text-3xl">API keys</h3>
                    </div>
                    <div>
                        <span>Learn more about API Authentication</span>
                    </div>
                </div>
                <div className="px-5 py-2">
                    <span>Viewing test API keys. Toggle to view live keys.</span>
                </div>
            </div>

            <div className="border rounded mt-5">
                <div className="p-5 border-b">
                    <div>
                        <h3 className="text-black font-bold text-xl">Standard keys</h3>
                    </div>
                    <div>
                        <span>These keys will allow you to authenticate API requests.</span>
                    </div>
                </div>
                <div className="grid grid-cols-3 grid-rows-2">
                    <span className="border-b px-5 py-2 self-center">Name</span>
                    <span className="border-b px-5 py-2 self-center">Token</span>
                    <span className="border-b px-5 py-2 self-center">Created</span>

                    <div className="border-b px-5 py-2">Publishable key</div>
                    <div className="border-b px-5 py-2">
                        <ReactTooltip id="publicKey" place="right" effect="solid">
                            {clipboard}
                        </ReactTooltip>
                        <span onClick={() => {
                            navigator.clipboard.writeText(pk).then(() => {
                                setClipboard("Copied to clipboard");
                                // set timeout to reset tooltip
                                setTimeout(() => {
                                    setClipboard("Click to copy");
                                }, 700);
                            });
                        }} data-tip data-for="publicKey" className="break-words">
                            {pk}
                        </span>
                    </div>
                    <div className="border-b px-5 py-2">12 Jul 2021</div>

                    <span className="px-5 py-2">Secret key</span>
                    <div className="px-5 py-2">
                        <span onClick={() => {
                            navigator.clipboard.writeText(sk).then(() => {
                                setClipboard("Copied to clipboard");
                                // set timeout to reset tooltip
                                setTimeout(() => {
                                    setClipboard("Click to copy");
                                }, 700);
                            });
                        }} data-tip data-for="publicKey" className="break-words" style={{filter: "blur(5px)"}}>{sk}</span>
                    </div>
                    <span className="px-5 py-2">12 Jul 2021</span>
                </div>

            </div>

        </div>
    );
}

export default ApiKey;