import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {MdOutlineClose} from "react-icons/md";
import clsx from "clsx";
import {useDispatch, useSelector} from "react-redux";
import {getPhoneNumberFormat} from "../../../@yb/utils/getPhoneNumberFormat";
import MobileMoneyInput from "../../../@yb/components/Input/mobileMoneyInput";
import {setCountry, setOperatorList} from "../../../redux/global";
import localData from "../../../data";
import {getCountries, setSelectedCountry, setSelectedOperator} from "../../../redux/features/countrySlice";
import {NumericFormat} from 'react-number-format';
import notification from "../../../@yb/components/notification";
import ky from "ky";
import Constants from "../../../@yb/utils/constants";

function CreateTransfer() {

  const [step, setStep] = React.useState(1);
  const {selectedAccount} = useSelector(state => state.account)
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const [currency, setCurrency] = useState("XAF");
  const [senderInformation, setSenderInformation] = useState({
    type: "momo",
    countryId: "",
    operatorId: "",
    momo: {
      country: "",
      msisdn: "",
      operator_name: "",
    }
  })
  const [receiverInformation, setReceiverInformation] = useState({
    type: "momo",
    countryId: "",
    operatorId: "",
    momo: {
      country: "",
      msisdn: "",
      operator_name: "",
    }
  })

  const {countries, selectedCountry, selectedOperator} = useSelector((state) => state.country);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  function getOperator() {
    switch (selectedCountry) {
      case "congo":
        dispatch(setOperatorList(localData.operator.congo));
        dispatch(setCountry(localData.country[0]));
        break;
      case "niger":
        dispatch(setOperatorList(localData.operator.niger));
        dispatch(setCountry(localData.country[1]));

        break;
      case "drcongo":
        dispatch(setOperatorList(localData.operator.drcongo));
        dispatch(setCountry(localData.country[2]));
        break;
      default:
        return dispatch(setOperatorList([]));
    }
  }

  useEffect(() => {
    dispatch(getCountries());

    if (selectedCountry) {
      getOperator();
    }
  }, [selectedCountry]);

  const onReceiverCountryChange = (e) => {
    const s = countries.find((item) => item.id === parseInt(e.target.value));
    dispatch(setSelectedCountry(s));
    setReceiverInformation({
      ...receiverInformation,
      momo: {...receiverInformation.momo, country: s.code},
      countryId: s.id
    })
    setCurrency(s?.currency?.code);
  }

  const onReceiverOperatorChange = (e) => {
    const o = selectedCountry.operators.find((item) => item.id === parseInt(e.target.value));
    dispatch(setSelectedOperator(o));
    setReceiverInformation({
      ...receiverInformation,
      momo: {...receiverInformation.momo, operator_name: o.name},
      operatorId: o.id
    })
  }


  const onSenderCountryChange = (e) => {
    const s = countries.find((item) => item.id === parseInt(e.target.value));
    dispatch(setSelectedCountry(s));
    setSenderInformation({...senderInformation, momo: {...senderInformation.momo, country: s.code}, countryId: s.id})
  }

  const onSenderOperatorChange = (e) => {
    const o = selectedCountry.operators.find((item) => item.id === parseInt(e.target.value));
    dispatch(setSelectedOperator(o));
    setSenderInformation({
      ...senderInformation,
      momo: {...senderInformation.momo, operator_name: o.name},
      operatorId: o.id
    })
  }

  const send = async () => {
    const secretKey = selectedAccount.is_live ? selectedAccount.pk_live : selectedAccount.pk_test

    const data = {
      receiver: receiverInformation,
      amount: amount,
      currency: currency,
      public_key: secretKey,
    }

    if (amount === 0) return notification.error("Veuillez entrer un montant valide")

    console.log(data)

    try {
      setLoading(true)
      const response = await ky.post(`${Constants.getPaymentUrl(selectedAccount)}/api/v1/yabetoo/transfer`, {
        json: data,
        headers: {Authorization: `Bearer ${localStorage.getItem("token")}`}
      }).json()

      if (response.status === "succeeded" && response.ok) {
        notification.success("Transfert effectué avec succès")
      } else {
        notification.error("Une erreur est survenue lors du transfert")
      }

      setTimeout(() => {
        navigate("/payment")
      }, 3000)

    } catch (e) {
      notification.error("Vous n'êtes pas autorisé à effectuer cette action")
      console.error(e)
    } finally {
      setLoading(false)
    }
  }


  const receiverStep = () => {
    return (
      <>
        <div className="mx-auto w-1/3 mt-10">
          <filedset>
            <legend className="text-sm font-medium text-gray-900">Montant</legend>
            <NumericFormat
              allowNegative={false}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              prefix={`${selectedAccount.country.currency.code ?? "EUR"} `.toUpperCase()}
              onValueChange={(values, sourceInfo) => {
                setAmount(values.floatValue)
              }}
            />
          </filedset>
        </div>
        <div className="mx-auto w-1/3 mt-10">
          <MobileMoneyInput
            countries={countries}
            countryId={receiverInformation.countryId}
            operatorId={receiverInformation.operatorId}
            onCountryChange={onReceiverCountryChange}
            operators={selectedCountry?.operators ?? []}
            format={getPhoneNumberFormat(receiverInformation.momo.country)}
            onPhoneChange={(event) => setReceiverInformation({
              ...receiverInformation,
              momo: {...receiverInformation.momo, msisdn: event.value}
            })}
            onOperatorChange={onReceiverOperatorChange}
          />

          <div className="col-span-6 pt-10">
            <button
              className="block w-full rounded-md bg-indigo-700 p-2.5 text-sm text-white transition hover:shadow-lg"
              onClick={() => send()}
            >
              {loading ? "Transfert en cours.." : "Envoyer"}
            </button>
          </div>
        </div>

      </>
    )
  }

  const senderStep = () => {
    return (
      <>
        <div className="mx-auto w-1/3 mt-10">
          <filedset>
            <legend className="text-sm font-medium text-gray-900">Montant</legend>
            <NumericFormat
              allowNegative={false}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              prefix={`${selectedAccount.country.currency.code ?? "EUR"} `.toUpperCase()}
              onValueChange={(values, sourceInfo) => {
                setAmount(values.floatValue)
              }}
            />
          </filedset>
        </div>
        <div className="mx-auto w-1/3 mt-10">
          <MobileMoneyInput
            countries={countries}
            countryId={senderInformation.countryId}
            operatorId={senderInformation.operatorId}
            onCountryChange={onSenderCountryChange}
            operators={selectedCountry?.operators ?? []}
            format={getPhoneNumberFormat(senderInformation.momo.country)}
            onPhoneChange={(event) => setSenderInformation({
              ...senderInformation,
              momo: {...senderInformation.momo, msisdn: event.value}
            })}
            onOperatorChange={onSenderOperatorChange}
          />
          <div className="col-span-6 pt-10 flex gap-5">
            <button
              className="block w-full rounded-md bg-black p-2.5 text-sm text-white transition hover:shadow-lg"
              onClick={() => setStep(1)}
            >
              Precedent
            </button>

            <button
              className="block w-full rounded-md bg-indigo-700 p-2.5 text-sm text-white transition hover:shadow-lg"
              onClick={() => send()}
            >
              {loading ? "Transfert en cours.." : "Envoyer"}
            </button>
          </div>
        </div>
      </>
    )
  }

  return (
    <main>
      <div className="border-b h-16 w-full flex justify-between items-center px-6 fixed z-10 bg-white">
        <div className="flex">
          <Link to="/payment"
                className="text-gray-500 hover:text-gray-700">
            <MdOutlineClose className="h-6 w-6 text-gray-600"/>
          </Link>
          <div className="border border-l h-6 w-0 ml-5"></div>
          <span className="ml-5">Faire un transfert</span>
        </div>
      </div>
      <div className="h-full w-full h-full pt-20">
        {/*<div className="mx-auto w-1/3">
          <h2 className="sr-only">Steps</h2>

          <div className="py-10">
            <ol
              className="flex items-center gap-2 font-medium text-gray-500 sm:gap-4"
            >
              <li className="flex">
                 <span className="rounded bg-green-50 p-1.5 text-green-600">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-3 w-3"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        </span>
              </li>

              <li className="flex items-center justify-center text-blue-600">
                <span className="h-6 w-6 rounded bg-blue-50 text-center font-bold leading-6">1</span>
                <span className="ml-2"> Bénéficiaire </span>
              </li>

              <li className="flex items-center justify-end">
                <span className={clsx("h-6 w-6 rounded  text-center  font-bold leading-6 text-gray-600", {
                  ["bg-blue-50 text-blue-600"]: step == 2
                })}>
                  2
                </span>

                <span className={clsx("ml-2", {
                  ["text-blue-600"]: step == 2
                })}> Payment </span>
              </li>
            </ol>
          </div>
          <hr/>
        </div>*/}

        {receiverStep()}

      </div>
    </main>
  );
}

export default CreateTransfer;