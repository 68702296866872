import React, {useState} from 'react';
import PropTypes from "prop-types";
import  {getCountries, getCountryCallingCode} from "react-phone-number-input";
import Input from 'react-phone-number-input/input'
import {HiSelector, HiOutlineSearch} from 'react-icons/hi';
import fr from 'react-phone-number-input/locale/fr'
import getCountryFlag from "./getCountryFlag";

function PhoneInputField({label, labelSize = "text-sm", defaultCountry = "FR" , onChange, defaultValue=null}) {

  const [country, setCountry] = useState(defaultCountry)
  const [value, setValue] = useState(defaultValue)


  return (
    <div className="w-full">

      {/*<PhoneInput
        placeholder="Enter phone number"
        international
        countryCallingCodeEditable={false}
        defaultCountry={"FR"}
        value={phone}
        className=""
        error={phone ? (isValidPhoneNumber(phone) ? undefined : 'Invalid phone number') : 'Phone number required'}
        onChange={(value) => setPhone(value)}/>*/}
      {label && <label className={`${labelSize} block mb-2 font-medium text-gray-700 pt-5`} htmlFor="app-input-field">{label}</label>}

      <div className="w-full flex">
        <CountrySelect
          labels={fr}
          value={country}
          onChange={setCountry}/>
        <Input
          country={country}
          value={value}
          international={false}
          placeholder="Enter phone number"
          className="w-full h-10 border border-gray-300 border-l-0 rounded-r-lg px-1 py-2 outline-0 border-gray-300 outline-0 focus:outline-0 focus:ring-0 focus:border-gray-300"
          onChange={(e) => {
            setValue(e)
            onChange(e)
          }}/>

      </div>

    </div>
  );
}


const CountrySelect = ({value, onChange, labels, ...rest}) => {

  const [query, setQuery] = useState('')
  const [showDropdown, setShowDropdown] = useState(false)
  const countries = getCountries()

  const filteredCountries =
    query === ''
      ? countries
      : countries.filter((c) =>
        labels[c]
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(query.toLowerCase().replace(/\s+/g, ''))
      )

  return (
    <div className="h-10">
      <div onClick={() => setShowDropdown(!showDropdown)} className="w-30 relative h-10 border border-gray-300 border-r-0 rounded-l-lg pl-3 py-2 pr-2 outline-0 border-gray-300 flex items-center">
        {getCountryFlag(value)}
        <HiSelector className="mx-2"/>
        <span>
         +{getCountryCallingCode(value)}
      </span>
      </div>
      {showDropdown && (
        <div className="absolute border mt-2 bg-white rounded shadow w-96">
          <div className="flex items-center border-b h-8">
            <HiOutlineSearch/>
            <input autoComplete="off" onChange={(value) => setQuery(value.target.value)}
                   className="w-full border-none py-1 px-2 outline-0 text-sm"/>
          </div>
          <ul className="bg-white overflow-y-auto max-h-72">
            {filteredCountries.map((country) => (
              <li key={country}
                  className="w-full flex items-center justify-between text-sm hover:bg-gray-50 hover:cursor-pointer px-2 py-1"
                  onClick={() => {
                    onChange(country)
                    setShowDropdown(false)
                  }}>
                <div className="flex items-center">
                  {getCountryFlag(country)}
                  <span className="ml-2">{labels[country]}</span>
                </div>
                <div className="ml-5">
                  +{getCountryCallingCode(country)}
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>

    /*<select
      {...rest}
      value={value}
      className="border px-2 mr-0 border-gray-300 border-r-0 rounded-l-lg h-10 w-44"
      onChange={event => onChange(event.target.value || undefined)}>
      <option value="">
        {labels['ZZ']}
      </option>
      {getCountries().map((country) => (
        <option key={country} value={country}>
          {flags[country] } {labels[country]} +{getCountryCallingCode(country)}
        </option>
      ))}
    </select>*/
  )
}

CountrySelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  labels: PropTypes.objectOf(PropTypes.string).isRequired
}


export default PhoneInputField;