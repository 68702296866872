import React, { useEffect, useState } from 'react';

function Payment() {
    const [modalDate, setModalDate] = useState(false)
    const [modalMontant, setModalMontant] = useState(false)
    const [modalEtat, setModalEtat] = useState(false)

    return (
        <div className="h-full">
            <h3 className="mb-4 text-black font-bold text-3xl">Payment</h3>
            <div class="flex">
                <div>
                    <div onClick={() => { setModalDate(true); setModalMontant(false); setModalEtat(false) }} class="border rounded-full px-2 text-gray-500 mr-2">Date</div>
                    {modalDate &&
                        <div tabindex="-1" class="fixed overflow-y-auto overflow-x-hidden z-50 mt-2" >
                            <div class="relative  rounded-lg bg-white w-80 border p-2">
                                <div class="font-bold text-gray-600">Filter by date</div>
                                <div class="font-bold text-white bg-indigo-500 text-center m-2 rounded">Appliquer</div>
                            </div>
                        </div>}

                </div>
                <div class="border rounded-full px-2 text-gray-500 mr-2">
                    <div onClick={() => { setModalDate(false); setModalMontant(true); setModalEtat(false) }}>
                        Amount
                        {modalMontant &&
                            <div tabindex="-1" class="fixed overflow-y-auto overflow-x-hidden z-50 mt-2" >
                                <div class="relative  rounded-lg bg-white w-80 border p-2">
                                    <div class="font-bold text-gray-600">Filter by Amount</div>
                                    <div class="font-bold text-white bg-indigo-500 text-center m-2 rounded">Appliquer</div>
                                </div>
                            </div>}
                    </div>
                </div>
                <div class="border rounded-full px-2 text-gray-500 mr-2">
                    <div onClick={() => { setModalDate(false); setModalMontant(false); setModalEtat(true) }}>
                        State
                        {modalEtat &&
                            <div tabindex="-1" class="fixed overflow-y-auto overflow-x-hidden z-50 mt-2" >
                                <div class="relative  rounded-lg bg-white w-80 border p-2">
                                    <div class="font-bold text-gray-600">Filter by State</div>
                                    <div class="font-bold text-white bg-indigo-500 text-center m-2 rounded">Appliquer</div>
                                </div>
                            </div>}</div>
                </div>
                <div class="flex-1"></div>
                <div class="px-2 text-gray-400 mr-2">Remove filter</div>
            </div>
            <hr class='border mt-4'/>
            <div className="flex items-center justify-center mt-52 w-full">
                <div className="flex-col w-1/3">
                    <div className="pb-5">
                        <svg xmlns="http://www.w3.org/2000/svg"
                            className="h-12 w-12 p-1 rounded bg-gray-200 text-gray-500" viewBox="0 0 20 20"
                            fill="currentColor">
                            <path fillRule="evenodd"
                                d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z"
                                clipRule="evenodd" />
                        </svg>
                    </div>
                    <div className="font-bold">Aucun virement test</div>
                    <div className="text-gray-500 font-medium">
                        Les virements test s'afficheront ici, ainsi que la date à laquelle ils devraient être crédités sur votre compte bancaire.
                    </div>
                    <a href="" className="font-medium flex items-center text-indigo-500">
                        <span className="mr-2">En savoir plus</span>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                        </svg>
                    </a>
                </div>
            </div>

        </div >
    );
}

export default Payment;