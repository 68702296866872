import React from 'react';
import Register from "../../../components/auth/register";

function SystemRegister() {
    return (
        <div>
            <Register/>
        </div>
    );
}

export default SystemRegister;