import React from 'react';
import InputField from "../../../@yb/components/Input/InputField";
import {useDispatch, useSelector} from "react-redux";
import {
  setCallbackUrl,
  setCollectUserDetails,
  setConfirmPageOption, setCustomMessageText,
  setShowCustomMessage
} from "../../../redux/features/payment/createPaymentLinkSlice";

function ConfirmTab(props) {


  const {confirmPageOption, showCustomMessage, customMessage, callBackUrl} = useSelector(state => state.paymentLink)
  const dispatch = useDispatch()

  return (
    <div className="py-5 w-full">
        <div className="flex flex-col w-full">
          <div className='mb-2'>
            <div>
              <input type="radio" checked={confirmPageOption === 1} onChange={() => dispatch(setConfirmPageOption(1))} />
              <span className="ml-2 text-sm font-medium">Show confirmation page</span>
            </div>

            {confirmPageOption === 1 && (
              <div>
                <div className="flex items-center mb-4 mt-2 ml-5">
                  <input id="default-checkbox" type="checkbox" value={showCustomMessage} onChange={(event) => {
                    dispatch(setShowCustomMessage(event.target.checked))
                  }} className="w-4 h-4 shadow-sm border border-gray-200 ring-0"/>
                  <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium">Replace default with custom message</label>
                </div>

                {showCustomMessage && (
                 <div className="w-full ml-10">
                   <InputField value={customMessage} onChange={(event) => {
                     console.log(event)
                      dispatch(setCustomMessageText(event))
                   }} type='textarea'/>
                 </div>
                )}
              </div>
            )}

          </div>
          <div className="mt-2 w-full">
            <div className="flex items-start justify-start">
              <input type="radio" checked={confirmPageOption === 2} onChange={() => dispatch(setConfirmPageOption(2))} />
              <div className="flex flex-col -mt-1">
                <span className="ml-2 text-sm font-medium">Don't show confirmation page</span>
                <p className="text-xs text-gray-500 ml-2">The customer will be redirected to the success page after payment.</p>
              </div>
            </div>


            {confirmPageOption === 2 && (
              <div className="flex items-center mb-4 mt-2 ml-5 w-full">
                <InputField value={callBackUrl} onChange={(v) => dispatch(setCallbackUrl(v)) } size="xs" shadow={true} />
              </div>
            )}

          </div>
        </div>
    </div>
  );
}

export default ConfirmTab;