import React, {useMemo} from 'react';
import {Link} from "react-router-dom";
import {HiOutlineX} from "react-icons/hi";
import AccountSideBar from "../../components/account/AccountSideBar";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentStep} from "../../redux/features/account/activateAcountSlice";
import BusinessType from "../../components/account/BusinessType";
import PersonalInformation from "../../components/account/PersonalInformation";
import BusinessDetails from "../../components/account/BusinessDetails";
import BankAccount from "../../components/account/BankAccount";

function ActivateAccount(props) {

  const dispatch = useDispatch();

  const {currentStep}= useSelector(state => state.activateAccount);

  const items = useMemo(() => [
    {
      name: 'Verify your business',
      id: 1,
      steps: [
        {
          name: 'Business type',
          component: <BusinessType/>,
          id: 1.1
        },
        {
          name: 'Personal information',
          component: <PersonalInformation/>,
          id: 1.2
        },
        {
          name: 'Business details',
          component: <BusinessDetails/>,
          id: 1.3
        },

      ]
    },
    {
      name: 'Add your bank account',
      id: 2,
      steps: [
        {
          name: 'Bank account',
          component: <BankAccount />,
          id: 2.1
        }
      ]
    }
  ], []);


  return (
    <form>
      <main>
        <div className="border-b h-16 w-full flex justify-between items-center px-6 fixed bg-white">
          <div className="flex items-center justify-center">
            <Link to="/payment" className="text-gray-500 hover:text-gray-700">
              <HiOutlineX className="h-5 w-5 text-gray-600"/>
            </Link>
            <div className="border border-l h-6 w-0 ml-5"></div>
            <span className="ml-5 text-sm">Activer mon compte</span>
          </div>
        </div>
        <div className="pt-24 px-10 pb-10">
          <AccountSideBar items={items} onStepChange={(value) => dispatch(setCurrentStep(value))} current={currentStep}/>
        </div>

      </main>
    </form>
  );
}

export default ActivateAccount;