import axios from "axios";

export const getCustomer = async (id, query) => {
  return await axios
    .get(`${process.env.REACT_APP_AUTH_URL}/v1/account/${id}/clients?name=${query}`, {
      headers: {
        authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then((res) => res.data);
}