import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {logger} from "redux-logger";
import {
  persistReducer, persistStore,
  PURGE,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import accountSlice from "./features/account/accountSlice";
import user from "./user";
import countrySlice from "./features/countrySlice";
import thunk from "redux-thunk";
import operatorSlice from "./features/operatorSlice";
import loginSlice from "./features/auth/loginSlice.js";
import meSlice from "./features/auth/meSlice";
import invoiceSlice from "./features/invoices/crud/invoiceSlice";
import productSlice from "./features/product/productSlice";
import customerSlice from "./features/customer/customerSlice";
import billSlice from "./features/invoices/billSlice";
import addProductSlice from "./features/product/addProductSlice";
import InvoiceModeSlice from "./features/invoices/invoiceModeSlice";
import SendInvoiceSlice from "./features/invoices/status/sendSlice";
import DuplicatedInvoiceSlice from "./features/invoices/status/duplicatedSlice";
import DeleteInvoiceSlice from "./features/invoices/crud/deleteSlice";
import CancelInvoiceSlice from "./features/invoices/status/cancelSlice";
import createPaymentLinkSlice from "./features/payment/createPaymentLinkSlice";
import customerInputSlice from "./features/customer/customerInputSlice";
import UpdateInvoiceSlice from "./features/invoices/crud/updateInvoiceSlice";
import ActivateAcountSlice from "./features/account/activateAcountSlice";
import global from "./global";

const reducers = combineReducers({
  invoice: invoiceSlice,
  country: countrySlice,
  account: accountSlice,
  user,
  operator: operatorSlice,
  login: loginSlice,
  me: meSlice,
  product: productSlice,
  customer: customerSlice,
  bill: billSlice,
  addProduct: addProductSlice,
  invoiceMode: InvoiceModeSlice,
  sending: SendInvoiceSlice,
  duplicat: DuplicatedInvoiceSlice,
  deleteOneInvoice: DeleteInvoiceSlice,
  cancelOneInvoice: CancelInvoiceSlice,
  paymentLink: createPaymentLinkSlice,
  customerInput: customerInputSlice,
  updateInvoice: UpdateInvoiceSlice,
  activateAccount: ActivateAcountSlice,
  global,
});

export const persistConfig = {
  key: "root",
  storage,
};

const middlewares = [thunk];

if (process.env.NODE_ENV === "development") {
  middlewares.push(logger);
}

const resettableRootReducer = (state, action) => {
  if (action.type === PURGE) {
    storage.removeItem("persist:root");
    return reducers(undefined, action);
  }
  return reducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, resettableRootReducer);


const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: middlewares,
});

let persistor = persistStore(store);

export {store, persistor};


