import React from 'react';
import ProductOverview from "../../components/product/overview";
import {Tab, Tabs} from "../../@yb/components/Tabs/Tabs";
import {useDispatch, useSelector} from "react-redux";
import {setProductActive} from "../../redux/features/product/productSlice";
import ProductEmpty from "../../components/product/productEmpty";


const Products = () => {

const {active} =  useSelector(state => state.product);
const dispatch = useDispatch();

    return (
            <Tabs title="Products" active={active} onChange={(e) => {
              dispatch(setProductActive(e));
            }}>
                <Tab title='Tous les produits'>
                  <ProductOverview />
                </Tab>
               {/* <Tab title='Coupons'>
                  <ProductEmpty />
                </Tab>*/}
            </Tabs>
    );
};

export default Products;
