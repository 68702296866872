import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import notification from "../../../@yb/components/notification";

export const getCustomers = createAsyncThunk(
  "customer/getCustomers",
  async (id) => {
    return axios
      .get(`${process.env.REACT_APP_AUTH_URL}/v1/account/${id}/clients`, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }
);

export const createCustomer = createAsyncThunk(
  "customer/createCustomer",
  async ({ customerData }) => {
    return axios
      .post(`${process.env.REACT_APP_INVOICE_API_URL}/customer`, customerData, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => res.data)
      .catch((e) => e.response);
  }
);

const customerSlice = createSlice({
  name: "customer",
  initialState: {
    customers: [],
    custome: null,
    isloading: false,
    error: null,
    selectedCustomer: null,
  },
  reducers: {
    setSelectedCustomer: (state, action) => {
      state.selectedCustomer = action.payload;
    },
    setRemoveCustomer: (state, action) => {
      state.selectedCustomer = null;
    },
  },
  extraReducers: {
    [getCustomers.pending]: (state, action) => {
      state.isloading = true;
    },
    [getCustomers.fulfilled]: (state, action) => {
      state.isloading = false;
      state.customers = action.payload.data;
    },
    [getCustomers.rejected]: (state, action) => {
      state.isloading = false;
      state.error = action.payload;
    },

    [createCustomer.pending]: (state, action) => {
      state.isloading = true;
    },
    [createCustomer.fulfilled]: (state, action) => {
      state.isloading = false;
      state.custome = action.payload.data;
      notification.success(action?.payload?.message);
    },
    [createCustomer.rejected]: (state, action) => {
      state.isloading = false;
      state.error = action.payload;
      notification.error(action?.payload?.message);
    },
  },
});
export const { setSelectedCustomer, setRemoveCustomer } = customerSlice.actions;
export default customerSlice.reducer;
