import React from 'react'
import {Toaster} from "react-hot-toast";
const AuthLayout = ({children}) => {
    return (
            <main className="bg-gray-100">
                <Toaster />
                {children}
            </main>
    )
}

export default AuthLayout
