import React, {useEffect, useState} from 'react';
import YabetooLogo from "../../../@yb/components/yabetooLogo";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {loginUser} from "../../../redux/features/auth/loginSlice.js";
import {
    setData,
    setIsAuthenticated,
} from "../../../redux/features/auth/meSlice";
import {setAccounts, setSelectedAccount} from "../../../redux/features/account/accountSlice";

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const {loading, success, error, data} = useSelector(state => state.login);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const login = async (event) => {
        localStorage.removeItem('persist:root');
        event.preventDefault();
        const payload = {
            email,
            password
        }
        // login user
        dispatch(loginUser(payload))
    }

    useEffect(() => {
        if (success) {
            dispatch(setData(data.user));
            dispatch(setIsAuthenticated(true));
            dispatch(setAccounts(data.user.accounts))

            dispatch(setSelectedAccount(data.user.accounts[0] ?? {}))

            navigate('/payment');

            /*if(isAuthenticated){
                if (data.user.accounts.length > 0) {
                    dispatch(setSelectedAccount(data.user.accounts[0]))
                    navigate('/');
                    notifier.success('Login successful');
                } else {
                    navigate('/account/create');
                }
            }*/
        }
    }, [success]);

    return (
        <div className="h-screen max-w-xl mx-auto flex flex-col space-y-10">
            <div className="w-72 pt-10 mx-auto">
                <YabetooLogo/>
            </div>
            <div className="bg-white p-12 shadow rounded p-5">
                <h1 className="text-3xl font-medium">S’identifier</h1>
                <p className="text-sm">Tenez-vous au courant des évolutions de votre monde professionnel</p>
                <p className="my-2 text-red-600">{error}</p>

                <form onSubmit={login} className="space-y-5 mt-5">
                    <label className="block text-sm font-medium text-gray-700">Email </label>
                    <input id="email" type="email" onChange={(event) => setEmail(event.target.value)}
                           className="w-full h-12 border border-gray-300 rounded px-3" placeholder="E-mail"/>
                    <label className="block text-sm font-medium text-gray-700">Mot de passe </label>
                    <input id="password" type="password" onChange={(event) => setPassword(event.target.value)}
                           className="w-full h-12 border border-gray-300 rounded px-3" placeholder="Mot de passe"/>

                    <div className="">
                        <a href="#" className="font-medium text-blue-700 hover:bg-blue-300 rounded-full p-2">Mot de
                            passe oublié ?</a>
                    </div>

                    <button id="button-submit" type='submit'
                            className="flex items-center justify-center text-center w-full bg-blue-700 rounded text-white py-3 font-medium">
                        {loading ? <svg className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                    strokeWidth="4"/>
                            <path className="opacity-75" fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                        </svg> : <span className="font-medium subpixel-antialiased">Continuer</span>}
                    </button>
                </form>
                <div className="flex items-center justify-center space-x-2 my-5">
                    <span className="h-px w-16 bg-gray-200"></span>
                    <span className="text-gray-300 font-normal">ou</span>
                    <span className="h-px w-16 bg-gray-200"></span>
                </div>
                <div className="flex w-full justify-center">
                    <p className="mx-auto">Nouveau sur Yabetoo ? <Link to="/auth/register">
                        <span className="text-blue-700 font-medium">S’inscrire</span>
                    </Link></p>
                </div>
            </div>
        </div>
    );
}

export default Login;