import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getPaymentLink} from "../../../redux/features/payment/createPaymentLinkSlice";
import notification from "../../../@yb/components/notification";
import moment from "moment/moment";
import Pagination from "../../../@yb/components/Pagination/Pagination";
import Table from "../../../@yb/components/Table/Table";
import {Menu, Transition} from "@headlessui/react";
import {HiDotsHorizontal} from "react-icons/hi";
import EmptyPayment from "../../../@yb/components/emptyPayment";
import axios from "axios";
import {Badge} from "@tremor/react";
import Constants from "../../../@yb/utils/constants";

moment.locale('fr');

function PaymentLink(props) {
  const {selectedAccount} = useSelector(state => state.account);
  const {paymentLink} = useSelector(state => state.paymentLink);
  const [postsPerPage] = useState(5);

  const dispatch = useDispatch();

  useEffect(() => {
    fetchPaymentLinks()
  }, [selectedAccount]);

  // Change page


  const fetchPaymentLinks = async (page = 1) => {
    const account = selectedAccount.is_live ? selectedAccount.pk_live : selectedAccount.pk_test;
    dispatch(getPaymentLink({account, page, postsPerPage, selectedAccount}));
  }

  const columns = useMemo(() => [
    ,
    {
      header: 'Titre',
      accessor: 'title',
      //render: (created_at) => moment(created_at).format("LL")
    },
    {
      header: '',
      accessor: 'id',
      render: (id) => {
        return (
          <div className="flex items-center ">
              <span
                className="bg-gray-100 border rounded-lg p-1">{`https://${selectedAccount?.is_live === 1 ? "checkout" : "dev"}.yabetoopay.com/link/${id}`.slice(0, 50)}...</span>
            <div data-for={id} onClick={() => {
              navigator.clipboard.writeText(`https://${selectedAccount?.is_live === 1 ? "checkout" : "dev"}.yabetoopay.com/link/${id}`).then(() => {
                notification.success("Le lien de paiement a été copié dans le presse-papier.")
              });
            }} className="border p-1 ml-4 rounded cursor-pointer ">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-3 h-3">
                <path fillRule="evenodd"
                      d="M10.5 3A1.501 1.501 0 009 4.5h6A1.5 1.5 0 0013.5 3h-3zm-2.693.178A3 3 0 0110.5 1.5h3a3 3 0 012.694 1.678c.497.042.992.092 1.486.15 1.497.173 2.57 1.46 2.57 2.929V19.5a3 3 0 01-3 3H6.75a3 3 0 01-3-3V6.257c0-1.47 1.073-2.756 2.57-2.93.493-.057.989-.107 1.487-.15z"
                      clipRule="evenodd"/>
              </svg>
            </div>
          </div>
        )
      }
    },
    {
      header: '',
      accessor: 'active',
      render: (active) => {
        return (

          active ?
            <Badge
              text="activer"
              color="green"
              size="sm"
              icon={undefined}
              tooltip=""
              marginTop="mt-0"/>
            :
            <Badge
              text="désactiver"
              color="red"
              size="sm"
              icon={undefined}
              tooltip=""
              marginTop="mt-0"/>


        )
      }
    }
  ], []);

  //send request to activate or deactivate payment link
  const activatePaymentLink = async (id, active) => {
    axios
      .put(
        `${Constants.getPaymentUrl(selectedAccount)}/api/v1/payment-links/${id}/edit`,
        {
          active: !active
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        notification.success("Le lien de paiement a été mis à jour avec succès");
        fetchPaymentLinks();
      }).catch((err) => {
      notification.error("Une erreur s'est produite lors de la mise à jour du lien de paiement");
    });
  }

  function MyDropdown(item) {
    return (
      <>
        <Menu as="div" className=" inline-block text-left">
          <Menu.Button>
            <HiDotsHorizontal className="h-5 w-5 text-gray-400"/>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              className=" z-10 absolute right-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">

              <div className="px-1 py-1 ">
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to={`/payment-link/${item.id}`}
                      className={`${
                        active ? "bg-violet-500 text-white" : "text-gray-900"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      Show
                    </Link>
                  )}
                </Menu.Item>
              </div>

              <div className="px-1 py-1 ">
                <Menu.Item>
                  {({active}) => (
                    <div
                      className={`${
                        active ? "bg-violet-500 text-white" : "text-gray-900"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      onClick={async () => {
                        await activatePaymentLink(item.id, item.active)
                      }}
                    >
                      <span>{item.active ? "Désactiver" : "Activer"}</span>
                    </div>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </>
    );
  }

  return (
    <div className='h-full'>
      <div className="flex flex-row items-center mb-4">
        <h3 className="flex-1 text-black font-bold text-3xl">Lien de paiement</h3>
        <Link to="/payment-link/create"
              className="flex items-center rounded-lg bg-indigo-500 px-2 py-1 text-white">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-1" fill="none" viewBox="0 0 24 24"
               stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6m0 0v6m0-6h6m-6 0H6"/>
          </svg>
          Créer un lien de paiement de paiement
        </Link>
      </div>
      {paymentLink.data?.length ?
        <div>
          <Table
            data={paymentLink?.data}
            columns={columns}
            onActionClick={MyDropdown}
            pagination={<Pagination
              meta={paymentLink?.meta}
              onPageChange={fetchPaymentLinks}
            />}/>

        </div> :
        <EmptyPayment/>
      }
    </div>
  );
}

export default PaymentLink;