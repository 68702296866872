import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const addToBasket = createAsyncThunk(
  "bill/addToBasket",
  async ({ product, quantity }, { rejectWithValue }) => {
    try {
      const billData = { product, quantity };
      return billData;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addToUpdatedBasket = createAsyncThunk(
  "bill/addToUpdatedBasket",
  async (billItems, { rejectWithValue }) => {
    try {
      return billItems;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const billSlice = createSlice({
  name: "bill",
  initialState: {
    bills: [],
    updatedBills: null,
    isloading: false,
    isCancel: false,
    error: null,
  },
  reducers: {
    openCancel: (state) => {
      state.isCancel = true;
    },
    closeCancel: (state) => {
      state.isCancel = false;
    },
    setToBasket: (state, action) => {
      state.bills = [...action.payload];
    },
    removeToBasket: (state, action) => {
      state.bills = [];
    },
    setToUpdatedBasket: (state, action) => {
      state.updatedBills = [...action.payload];
    },
    removeToUpdateBasket: (state, action) => {
      state.updatedBills = [];
    },
  },

  extraReducers: {
    [addToBasket.pending]: (state, action) => {
      state.isloading = true;
    },
    [addToBasket.fulfilled]: (state, action) => {
      state.isloading = false;
      state.bills = [...state.bills, action.payload];
    },
    [addToBasket.rejected]: (state, action) => {
      state.isloading = false;
      state.error = action.payload;
    },
    [addToUpdatedBasket.pending]: (state, action) => {
      state.isloading = true;
    },
    [addToUpdatedBasket.fulfilled]: (state, action) => {
      state.isloading = false;
      state.updatedBills = [...state.updatedBills, action.payload];
    },
    [addToUpdatedBasket.rejected]: (state, action) => {
      state.isloading = false;
      state.error = action.payload;
    },
  },
});

export const {
  setToBasket,
  removeToBasket,
  removeToUpdateBasket,
  openCancel,
  closeCancel,
} = billSlice.actions;
export default billSlice.reducer;
