import {Dialog, Transition} from "@headlessui/react";
import React, {Fragment, useEffect, useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate, useParams} from "react-router-dom";
import InvoiceStatus from "../../components/invoices/InvoiceStatus";
import {setLimiteDate} from "../../redux/features/invoices/invoiceModeSlice";
import {cancelInvoice} from "../../redux/features/invoices/status/cancelSlice";
import {deleteInvoice} from "../../redux/features/invoices/crud/deleteSlice";
import {sendInvoice} from "../../redux/features/invoices/status/sendSlice";
import {getInvoieById} from "../../redux/features/invoices/crud/invoiceSlice";
import SelectCustomer from "../../@yb/components/select/SelectCustomer";

import {HiDownload} from "react-icons/hi";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {setSelectedCustomer} from "../../redux/features/customer/customerSlice";
import {duplicatedInvoice} from "../../redux/features/invoices/status/duplicatedSlice";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import YTable from "../../@yb/components/Table/Table";

const Details = () => {
  const dispatch = useDispatch();
  const {id} = useParams();
  const {
    invoice,
    account,
    customer,
    duplicat,
    sending,
    deleteOneInvoice,
    cancelOneInvoice,
  } = useSelector((state) => state);
  const {selectedAccount} = account;
  const {invoices} = invoice && invoice;
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getInvoieById(id));
  }, [id]);

  const [startDate, setStartDate] = useState(new Date());
  const [showModal, setShowModal] = useState(false);

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open4, setOpen4] = useState(false);

  const cancelButtonRef = useRef(null);
  const cancelButtonRef2 = useRef(null);
  const cancelButtonRef4 = useRef(null);

  const columns = useMemo(
    () => [
      {
        header: "",
        accessor: "product",
        render: (item) => {
          return <span>{item.name}</span>
        }
      },
      {
        header: "Référence",
        accessor: "product",
        render: (item) => {
          return <span>{item.reference}</span>
        }
      },
      {
        header: "Quantité",
        accessor: "quantity",
      },
      {
        header: "Montant",
        accessor: "product",
        render: (item) => {
          return item.price + ` ${selectedAccount.country.currency.code ?? "EUR"}`.toUpperCase();
        },
      }

    ],
    []
  );

  {
    /** dupliquer  */
  }
  const onDup = () => {
    const invoiceId = id;
    dispatch(duplicatedInvoice(invoiceId)).then((res) => {
      if (res?.meta?.requestStatus === "fulfilled") {
        dispatch(getInvoieById(id));
        setShowModal(false);
      }
    });
  };

  {
    /** send factures */
  }
  const onSend = () => {
    const invoiceId = id;
    dispatch(sendInvoice(invoiceId)).then((res) => {
      if (res?.meta?.requestStatus === "fulfilled") {
        dispatch(getInvoieById(id));
        setOpen2(false);
      }
    });
  };

  {
    /** delete  */
  }
  const onDelete = () => {
    const invoiceId = id;
    dispatch(deleteInvoice(invoiceId)).then((res) => {
      if (res?.meta?.requestStatus === "fulfilled") {
        setOpen(false);
        navigate("/payment");
      }
    });
  };

  {
    /** cancel  */
  }
  const onCancel = () => {
    const invoiceId = id;
    dispatch(cancelInvoice(invoiceId)).then((res) => {
      if (res?.meta?.requestStatus === "fulfilled") {
        dispatch(getInvoieById(id));
        setOpen4(false);
      }
    });
  };

  const setLimitDate = (date) => {
    setStartDate(date);
    dispatch(setLimiteDate(date));
  };

  return (
    <>
      <div className="flex flex-col mt-6">
        <div className="flex flex-row justify-between mb-4 items-center">
          <div className="flex flex-col">
            <div className="mb-5 w-32">
              <Link
                to="/payment"
                className="flex items-center rounded-lg border px-2 py-1.5 bg-white text-sm font-medium"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 pr-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10 19l-7-7m0 0l7-7m-7 7h18"
                  />
                </svg>
                Retour
              </Link>
            </div>

            {invoice.loading ? (
              <Skeleton height={20} width={300} className="mb-5"/>
            ) : (
              <div className="flex flex-row items-center">
                <span className="font-bold text-2xl">
                  {invoice?.invoice?.number}
                </span>
                <span className="text-gray-600 ml-1 m-2 text-2xl">pour</span>
                <span className="text-2xl">
                  {invoice?.invoice?.amount + " $"}
                </span>
                <span className="ml-5">
                  <InvoiceStatus status={invoice?.invoice?.status}/>
                </span>
              </div>
            )}
            <span className="text-sm">
              <span className="mr-2 text-gray-600">À régler avant le</span>
              {invoice.loading ? (
                <Skeleton height={20} width={100}/>
              ) : (
                invoice?.invoice?.due_date &&
                new Intl.DateTimeFormat("fr-FR", {dateStyle: "full"}).format(
                  new Date(invoices && invoice?.invoice?.due_date)
                )
              )}
            </span>
            {/*<div className="mt-5 flex gap-3">
              {invoice.loading ? (
                <>
                  <Skeleton height={25} width={150}/>
                  <Skeleton height={25} width={150}/>
                  <Skeleton height={25} width={150}/>
                  <Skeleton height={25} width={150}/>
                  <Skeleton height={25} width={150}/>
                </>
              ) : (
                <>

                  {invoice?.invoice?.status !== "pending" &&
                    invoice?.invoice?.status !== "paid" && (
                      <Link
                        className="bg-white text-gray-600 font-bold py-1 px-3 text-sm border border-gray-300 rounded-lg "
                        to={`/invoice/${id}/edit`}
                      >
                        Modifier la facture
                      </Link>
                    )}
                  {invoice?.invoice?.status !== "pending" &&
                    invoice?.invoice?.status !== "canceled" &&
                    invoice?.invoice?.status !== "paid" && (
                      <button
                        className="bg-white text-gray-600 font-bold py-1 px-3 text-sm border border-gray-300 rounded-lg "
                        onClick={() => setOpen2(true)}
                      >
                        Envoyer la facture
                      </button>
                    )}
                  <button
                    className="bg-white text-gray-600 font-bold py-1 px-3 text-sm border border-gray-300 rounded-lg "
                    onClick={() => setShowModal(true)}
                  >
                    Dupliquer la facture
                  </button>
                  {invoice?.invoice?.status !== "draft" &&
                    invoice?.invoice?.status !== "canceled" &&
                    invoice?.invoice?.status !== "paid" && (
                      <button
                        className="bg-white text-gray-600 font-bold py-1 px-3 text-sm border border-gray-300 rounded-lg "
                        onClick={() => setOpen4(true)}
                      >
                        Annuler
                      </button>
                    )}
                  {invoice?.invoice?.status !== "pending" &&
                    invoice?.invoice?.status !== "paid" && (
                      <button
                        className="bg-white text-red-600 font-bold py-1 px-3 text-sm border border-red-300 rounded-lg "
                        onClick={() => setOpen(true)}
                      >
                        Supprimer
                      </button>
                    )}
                </>
              )}
            </div>*/}
          </div>
        </div>
        <hr/>

        {/** resumer */}
        <div className="flex justify-between my-5">
          <div>
            <p className="text-gray-900 text-2xl font-bold">Résumé</p>
          </div>
          {invoice?.invoice && invoice?.invoice?.invoice_link !== null && (
            <a
              className="bg-white flex items-center gap-2 text-gray-600 font-bold py-1 px-3 text-sm border border-gray-300 rounded-lg cursor-pointer"
              href={invoice?.invoice?.invoice_link}
              target="_blank"
            >
              <HiDownload/>
              Facture pdf
            </a>
          )}
        </div>
        <hr/>

        <div className='mt-5'>
          <div className="flex justify-between">
            <div className="flex justify-between w-1/2 text-sm">
              <span className="mr-48">Facturé à </span>
              <span className="flex">
                <a href="to:mail" className="text-blue-500 lowercase">
                  {invoice?.invoice?.customer?.email}
                </a>
              </span>
            </div>
            <div>
              <p className="flex">
                <span className="text-gray-500 w-52">Numéro de facture</span>
                <span className="text-sm">
                  {invoice.loading ? (
                    <Skeleton width={200} height={15}/>
                  ) : (
                    invoice?.invoice?.number
                  )}
                </span>
              </p>
              <p className="flex">
                <span className="text-gray-500 w-52">Devise</span>
                <span className="text-sm">
                  {" "}
                  {invoice.loading ? (
                    <Skeleton width={200} height={15}/>
                  ) : (
                    selectedAccount?.country.currency.name ?? "Euro"
                  )}
                </span>
              </p>
              <p className="flex">
                <span className="text-gray-500 w-52">Date d'échéance </span>
                <span className="text-sm">
                  {invoice.loading ? (
                    <Skeleton width={200} height={15}/>
                  ) : (
                    invoice?.invoice?.due_date &&
                    new Intl.DateTimeFormat("fr-FR", {
                      dateStyle: "full",
                    }).format(new Date(invoices && invoice?.invoice?.due_date))
                  )}
                </span>
              </p>
            </div>
          </div>
          <div className="w-1/2 mt-10">
            <YTable
              columns={columns}
              data={invoice.invoice?.items ?? []}
            />
          </div>


        </div>
        <hr/>

        {/** Details */}
        <div className="flex justify-between my-5">
          <div>
            <p className="text-gray-900 text-2xl font-bold">Détails</p>
          </div>
        </div>
        <hr/>
        <div className="my-5  grid grid-cols-1 mb-10">
          <div className="grid grid-cols-2">
            <p className="text-sm text-gray-500">ID</p>
            <p className="">
              {invoice.loading ? (
                <Skeleton width={300} height={15}/>
              ) : (
                invoice?.invoice?.command_id
              )}
            </p>
          </div>
          <div className="grid grid-cols-2">
            <p className="text-sm text-gray-500">Créée le</p>
            <p className="">
              {invoice.loading ? (
                <Skeleton width={300} height={15}/>
              ) : (
                invoice?.invoice?.created_at &&
                new Intl.DateTimeFormat("fr-FR", {
                  dateStyle: "full",
                }).format(new Date(invoices && invoice?.invoice?.created_at))
              )}
            </p>
          </div>
          <div className="grid grid-cols-2">
            <p className="text-sm text-gray-500">Finalisé</p>
            <p className="">
              {invoice.loading ? (
                <Skeleton width={300} height={15}/>
              ) : (
                invoice?.invoice?.created_at &&
                new Intl.DateTimeFormat("fr-FR", {
                  dateStyle: "full",
                }).format(new Date(invoices && invoice?.invoice?.created_at))
              )}
            </p>
          </div>
          {/*<div className="grid grid-cols-2">
            <p className="text-sm text-gray-500">Page de paiement</p>
            <p className="text-blue-500">
              <a
                href={`https://${
                  selectedAccount?.livemode ? "checkout" : "dev"
                }.yabetoopay.com/invoice/${invoice?.invoice?.id}`}
                target="_blanc"
              >
                {invoice.loading ? (
                  <Skeleton width={300} height={15}/>
                ) : (
                  `https://${
                    selectedAccount?.is_live === 1 ? "checkout" : "dev"
                  }.yabetoopay.com/invoice/${invoice?.invoice?.id}`
                )}
              </a>
            </p>
          </div>*/}
          <div className="grid grid-cols-2">
            <p className="text-sm text-gray-500">Moyens de paiement</p>
            <p className="">
              {" "}
              {invoice.loading ? (
                <Skeleton width={300} height={15}/>
              ) : (
                "aucun"
              )}
            </p>
          </div>
        </div>

      </div>

    </>
  );
};

export default Details;
