import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import localData from "../../data";
import {setCountry, setOperatorList} from "../../redux/global";
import MobileMoneyInput from "../../@yb/components/Input/mobileMoneyInput";
import {Button} from "../../@yb/components/Button/Button";
import {setCurrentStep} from "../../redux/features/account/activateAcountSlice";
import formatDate from "../../@yb/utils/formatDate";
import {getCountries, setSelectedCountry, setSelectedOperator} from "../../redux/features/countrySlice";
import {getPhoneNumberFormat} from "../../@yb/utils/getPhoneNumberFormat";
import axios from "axios";
import notification from "../../@yb/components/notification";
import {useNavigate} from "react-router-dom";
import {fetchUser} from "../../redux/features/auth/meSlice";
import {setAccounts, setSelectedAccount} from "../../redux/features/account/accountSlice";

function BankAccount(props) {

  const {countries, selectedCountry, selectedOperator} = useSelector((state) => state.country);
  const {data} = useSelector(state => state.me);

  const {selectedAccount} = useSelector((state) => state.account);
  const {
    businessType, businessDetail, firstName,
    lastName,
    email,
    phone,
    address,
    birthDate,
    city,
    zipCode
  } = useSelector((state) => state.activateAccount);
  const dispatch = useDispatch();
  const [paymentPhone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  function getOperator() {
    switch (selectedCountry) {
      case "congo":
        dispatch(setOperatorList(localData.operator.congo));
        dispatch(setCountry(localData.country[0]));
        break;
      case "niger":
        dispatch(setOperatorList(localData.operator.niger));
        dispatch(setCountry(localData.country[1]));

        break;
      case "drcongo":
        dispatch(setOperatorList(localData.operator.drcongo));
        dispatch(setCountry(localData.country[2]));
        break;
      default:
        return dispatch(setOperatorList([]));
    }
  }

  useEffect(() => {
    dispatch(getCountries());

    if (selectedCountry) {
      getOperator();
    }
  }, [selectedCountry]);

  const onCountryChange = (e) => {
    const s = countries.find((item) => item.id === parseInt(e.target.value));
    dispatch(setSelectedCountry(s));
  }

  const onOperatorChange = (e) => {
    const o = selectedCountry.operators.find((item) => item.id === parseInt(e.target.value));
    dispatch(setSelectedOperator(o));
  }

  const saveData = async () => {
    let businessData = {
      businessTypeId: businessType,
      accountId: selectedAccount.id,
      personalDetail: {
        firstName: firstName,
        lastName: lastName,
        email: email,
        birthday: formatDate(birthDate),
        accountId: selectedAccount.id,
        phoneNumber: phone,
        address: address,
        city: city,
        zip: zipCode,
        state: null
      },
      businessDetail: {
        industryId: businessDetail.industryId,
        description: businessDetail.description,
        phoneNumber: businessDetail.phoneNumber,
        businessName: businessDetail.businessName,
        accountId: selectedAccount.id,
        vat: businessDetail.vat,
        website: businessDetail.website,
      },
      paymentMethod: {
        phoneNumber: paymentPhone,
        accountId: selectedAccount.id,
        operatorId: selectedOperator?.id
      }

    }

    axios.post(`${process.env.REACT_APP_AUTH_URL}/v1/account/activate`, businessData, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("token")
      }
    }).then((res) => {
      dispatch(fetchUser())
      dispatch(setAccounts(data.accounts))
      dispatch(setSelectedAccount(data.accounts[0]))
      notification.success("Votre compte a été activé avec succès, vous allez être redirigé vers votre tableau de bord");
      setTimeout(() => {
        navigate("/payment");
      }, 3000)

    }).catch((err) => {
      console.error(err);
      notification.error(err?.response?.data?.error?.message);
    })
  }


  return (
    <div>

      <p className="text-3xl">Let’s start with some basics</p>
      <span>Nous allons vous transferer les fonds sur ce numéro. </span>

      <MobileMoneyInput
        countries={countries}
        countryId={selectedCountry?.id}
        operatorId={selectedOperator?.id}
        onCountryChange={onCountryChange}
        operators={selectedCountry?.operators ?? []}
        format={getPhoneNumberFormat(selectedCountry?.code)}
        onPhoneChange={(event) => setPhone(event.value)}
        onOperatorChange={onOperatorChange}
      />

      <div className="mt-6">
        <Button block={true} size="sm" label="Continuer" onClick={() => saveData()}/>
      </div>

    </div>
  );
}

export default BankAccount;