import React, { Fragment, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NProgress from "nprogress";
import { Helmet } from "react-helmet";

import AuthLayout from "./auth";
import MainLayout from "./main";
import { useSelector } from "react-redux";
import CreateLayout from "./create";

const Layouts = {
  auth: AuthLayout,
  main: MainLayout,
  create: CreateLayout,
};

let previousPath = "";

const Layout = ({ children, title }) => {
  const { isAuthenticated } = useSelector((state) => state.me);

  // NProgress & ScrollTop Management
  const location = useLocation();

  const currentPath = location.pathname + location.search;
  if (currentPath !== previousPath) {
    window.scrollTo(0, 0);
    NProgress.start();
  }
  setTimeout(() => {
    NProgress.done();
    previousPath = currentPath;
  }, 300);

  // Layout Rendering
  const getLayout = () => {
    /*if (pathname === '/') {
            return 'public'
        }*/
    if (/^\/auth(?=\/|$)/i.test(location.pathname)) {
      return "auth";
    } else if (/create$/.test(location.pathname)) {
      return "create";
    } else if (/activate$/.test(location.pathname)) {
      return "create";
    } else if (/edit$/.test(location.pathname)) {
      return "create";
    }
    return "main";
  };

  const Container = Layouts[getLayout()];
  const isUserLoading = false; //user.loading
  const isAuthLayout = getLayout() === "auth";
  const navigate = useNavigate();

  const BootstrappedLayout = () => {
    useEffect(() => {
      if (!isAuthenticated) {
        navigate("/auth/login");
      }
      if (/^\/auth(?=\/|$)/i.test(location.pathname) && isAuthenticated) {
        navigate("/payment");
      }
    }, []);
    // in other case render previously set layout
    return <Container>{children}</Container>;
  };

  return (
    <Fragment>
      <Helmet titleTemplate={`${title} | %s`} title={title} />
      {BootstrappedLayout()}
    </Fragment>
  );
};

export default Layout;
