import React from 'react';
import CartProduct from "./cartProduct";
import YabetooIcon from "../../../@yb/components/icons/yabetooIcon";
import InputField from "../../../@yb/components/Input/InputField";
import { NumericFormat } from 'react-number-format';
import Footer from "../../../@yb/components/footer";
import {useSelector} from "react-redux";

function CheckoutPreview() {

  const {collectUserDetails, callToAction} = useSelector(state => state.paymentLink)


  return (
    <div className="w-full relative rounded-lg rounded-b-none bg-white shadow-lg mt-10 text-xs">
      <div className="absolute pt-2 flex items-center justify-between w-full">
        <div className='flex'>
          <div className='w-3 h-3 rounded-full bg-gray-200 mx-1'></div>
          <div className='w-3 h-3 rounded-full bg-gray-200 mr-1'></div>
          <div className='w-3 h-3 rounded-full bg-gray-200 mr-2'></div>
        </div>
        <div className="w-96 h-6 bg-gray-100 rounded-full flex items-center justify-center px-2">
          <span className="font-medium">{callToAction.toLowerCase()}.yabetoopay.com</span>
        </div>
        <div></div>
      </div>
      <div className="grid grid-cols-2 h-full w-full">
        <div className="flex flex-col pt-24 px-10 w-full">
          <CartProduct/>
        </div>
        <div className="sm:shadow-lg w-full flex flex-col items-start justify-center px-10 pt-20">
          <h1 className="text-gray-400 text-xs">Choisissez un moyen de paiement.</h1>
          <div className="pt-4 flex">
            <div
              className={`border-gray-600 border w-44 rounded-lg p-2 cursor-pointer`}>
              <YabetooIcon className="w-6 mb-1"/>
              <p>Mobile Money</p>
            </div>
          </div>
          {collectUserDetails && (
            <div className="w-full">
              <InputField onChange={() =>{}} value={''} placeholder="Email" type="email" label="Email" size="xs" labelSize="text-xs" shadow={true}/>
              <InputField onChange={() =>{}} value={''} placeholder="Nom" type="text" label="Nom" size="xs" labelSize="text-xs" shadow={true}/>
            </div>
          )}
          <div className="mt-6">
            <label htmlFor="price" className="block  font-medium text-gray-700 mb-2">
              Information mobile money
            </label>
            <select
              className="focus:outline-0 block w-full text-xs pr-12 border-gray-300 rounded-t-md border-t border-b-transparent text-gray-500"
            >
              <option>Selectionner un pays</option>
            </select>
            <div className="flex flex-col md:flex-row rounded-md shadow-sm">
              <div className=" flex items-center md:w-1/2">
                <select
                  className="focus:ring-indigo-500 block  focus:border-indigo-500 h-full  pl-2 pr-7 bg-transparent border-gray-300 text-gray-500 text-xs w-full md:rounded-bl-md"
                >
                  <option>Selectionner un operateur</option>
                </select>
              </div>

              <div className="md:w-1/2">
                <NumericFormat
                  type="tel"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 text-xs border-l-transparent border-t-transparent md:border-t-gray-300 border-l-gray-300 md:border-l-0 border-gray-300 md:rounded-br-md rounded-b-md md:rounded-bl-none"
                  placeholder="Numéro de telephone"
                />
              </div>
            </div>
          </div>
          <div className="w-full pt-6">
            <button
              disabled={true}
              className="flex items-center justify-center bg-indigo-500 text-white active:bg-pink-600 font-bold  text-sm px-4 py-2 rounded shadow w-full hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
            >
              <span className="font-medium subpixel-antialiased text-xs">{callToAction}</span>
            </button>
          </div>
          <Footer/>
        </div>
      </div>

    </div>
  );
}

export default CheckoutPreview;