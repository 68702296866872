export function getPhoneNumberFormat(code) {
  switch (code) {
    case "cg":
      return "+242 ## ### ####";
    case "ne":
      return "+227 ### ### ##";
    case "cd":
      return "+222 ### ## ##";
    default:
      return null;
  }
}