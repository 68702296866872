import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";
import notification from "../../../@yb/components/notification";

export const ActivateAccount = createAsyncThunk('account/create', async (data) => {
  return axios.post(`${process.env.REACT_APP_AUTH_URL}/v1/account/create`, data, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  }).then(res => res.data);
})

const activateAccountSlice = createSlice({
    name: 'activateAccount',
    initialState: {
      currentStep: {item: 0, step: 0},
      businessType: null,
      industry: null,
      firstName: null,
      lastName: null,
      email: null,
      birthDate: null,
      phone: null,
      address: null,
      city: null,
      zipCode: null,
      businessDetail: {
        industryId: null,
        description: "",
        phoneNumber: null,
        businessName: "",
        accountId: null,
        vat: "",
        website: ""
      },

    },
    reducers: {
      setCurrentStep: (state, action) => {
        state.currentStep = action.payload
      },
      setBusinessType: (state, action) => {
        state.businessType = action.payload
      },
      setFirstName: (state, action) => {
        state.firstName = action.payload
      },
      setLastName: (state, action) => {
        state.lastName = action.payload
      },
      setEmail: (state, action) => {
        state.email = action.payload
      },
      setBirthDate: (state, action) => {
        state.birthDate = action.payload
      },
      setPhone: (state, action) => {
        state.phone = action.payload
      },
      setAddress: (state, action) => {
        state.address = action.payload
      },
      setCity: (state, action) => {
        state.city = action.payload
      },
      setZipCode: (state, action) => {
        state.zipCode = action.payload
      },
      setIndustry: (state, action) => {
        state.businessDetail.industryId = action.payload
      },
      setBusinessName: (state, action) => {
        state.businessDetail.businessName = action.payload
      },
      setBusinessDescription: (state, action) => {
        state.businessDetail.description = action.payload
      },
      setBusinessPhoneNumber: (state, action) => {
        state.businessDetail.phoneNumber = action.payload
      },
      setBusinessVat: (state, action) => {
        state.businessDetail.vat = action.payload
      },
      setBusinessWebsite: (state, action) => {
        state.businessDetail.website = action.payload
      },
      setAccountId: (state, action) => {
        state.businessDetail.accountId = action.payload
      },

    },
    extraReducers: {
      [ActivateAccount.pending]: (state, action) => {
        state.loading = true
      },
      [ActivateAccount.fulfilled]: (state, action) => {
        state.accounts.push(action.payload)
        state.selectedAccount = action.payload
        state.loading = false
        notification.success('Account created successfully');
      },
      [ActivateAccount.rejected]: (state, action) => {
        state.loading = false
        state.error = action.payload
        notification.error('Store creation failed');
      },
    },
  }
)

export const {
  setCurrentStep,
  setBusinessType,
  setAddress,
  setBirthDate,
  setCity,
  setEmail,
  setFirstName,
  setLastName,
  setPhone,
  setZipCode,
  setIndustry,
  setBusinessName,
  setBusinessDescription,
  setBusinessPhoneNumber,
  setBusinessVat,
  setBusinessWebsite,
  setAccountId
} = activateAccountSlice.actions
export default activateAccountSlice.reducer