import React from "react";
import ProductInput from "../../../../@yb/components/ProductInput";
import {useDispatch, useSelector} from "react-redux";
import InputField from "../../../../@yb/components/Input/InputField";
import {setTitle} from "../../../../redux/features/payment/createPaymentLinkSlice";

function PaymentTapType1() {
  const {collectUserDetails, cartProducts} = useSelector(
    (state) => state.paymentLink
  );
  const dispatch = useDispatch();
  const {title} = useSelector(state => state.paymentLink)


  return (
    <div>
      <div className="py-3">
        <InputField label="Titre" shadow={true} onChange={(v) => dispatch(setTitle(v))} size="xs" type="text" value={title} placeholder="Ajouter un titre à votre page de paiement"/>
      </div>
      <div className="py-5">
      <span className="font-bold text-2xl">
        Product{" "}
        <span className="text-sm text-gray-400">
          {cartProducts.length > 1 ? `(${cartProducts.length} produits)` : null}
        </span>{" "}
      </span>
        <ProductInput/>
      </div>
    </div>
  );
}

export default PaymentTapType1;
