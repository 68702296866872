import React, {useState} from "react";
import notification from "../notification";


export default function Pagination({ meta, onPageChange }) {
  const [currentPage, setCurrentPage] = useState(1);
  const lastPage = meta?.last_page ?? 1;


  const paginateFront = async () => {
    if (meta?.next_page_url) {
      setCurrentPage(currentPage + 1);
      await onPageChange(currentPage + 1);
    } else {
      notification.success("Vous êtes à la dernière page");
    }
  };

  const paginateBack = async () => {
    if (meta?.previous_page_url) {
      setCurrentPage(currentPage - 1);
      await onPageChange(currentPage - 1);
    } else {
      notification.success("Vous êtes déjà sur la première page");
    }
  };

  return (
    <div className='py-2 flex items-center justify-between'>
      <span
        className='relative inline-flex items-center px-2 py-1 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer'
        onClick={async () => {
         await paginateBack();
        }}
      > Previous </span>



      <span >
       Page {currentPage} / {lastPage}
      </span>
      <span
        onClick={async () => {
         await paginateFront()
        }}
        className='relative inline-flex items-center px-2 py-1 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer'
      >Next</span>

    </div>
  );
}