import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import Topbard from "../../components/invoices/Topbard";
import {useParams} from "react-router-dom";
import {getInvoieById} from "../../redux/features/invoices/crud/invoiceSlice";
import InvoiceDueDate from "../../components/invoices/InvoiceDueDate";
import ProductInput from "../../@yb/components/invoices/ProductInput";
import {setCartProducts} from "../../redux/features/payment/createPaymentLinkSlice";
import InvoiceAddCustomer from "../../components/invoices/InvoiceAddCustomer";
import {setCustomer} from "../../redux/features/customer/customerInputSlice";

const Edit = () => {

  const {cartProducts} = useSelector(
    (state) => state.paymentLink
  );

  const {account, invoice} = useSelector((state) => state);

  const dispatch = useDispatch();
  const id = account?.selectedAccount?.id;
  const detailToUpdate = invoice?.invoice && invoice?.invoice?.items;
  const invoiceId = useParams();

  const updateBase = {
    invoiceId: invoiceId?.id,
    cartProducts: cartProducts,
  };

  useEffect(() => {
    dispatch(setCartProducts(invoice?.invoice?.items ?? []));
    dispatch(setCustomer(invoice?.invoice?.customer ?? {}));
  }, [detailToUpdate]);

  useEffect(() => {
    dispatch(getInvoieById(invoiceId?.id));
  }, [id]);

  return (
    <>
      <main className="bg-white h-full">
        <Topbard isEdit={true} updateBase={updateBase}/>

        <div className="w-1/2 mx-auto h-full bg-white pt-20">
          <div className="w-3/4  pt-5 h-full space-y-14">
            <InvoiceAddCustomer width="w-full" showNavigation={false}/>
            <hr/>
            <ProductInput showNavigation={false} width="w-full"/>
            <InvoiceDueDate width="w-full" showNavigation={false} initDate={new Date(invoice?.invoice?.due_date)}/>
          </div>
        </div>
      </main>
    </>
  );
};

export default Edit;
