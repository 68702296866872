import React from 'react'
import {Toaster} from "react-hot-toast";
const CreateLayout = ({children}) => {
  return (
    <>
      <Toaster />
      {children}
    </>
  )
}

export default CreateLayout
