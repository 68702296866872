import React from 'react';
import {Tab, Tabs} from "../../../@yb/components/Tabs/Tabs";
import Payments from "../payment/payments";
import {Link} from "react-router-dom";
import TransferList from "./transferList";

function Transfer() {
  return (
    <div className="h-full">
      <div className="flex flex-row items-center mb-4">
        <h3 className="flex-1 text-black font-bold text-3xl">Transfer</h3>
        <Link to="/transfer/create"
           className="flex items-center rounded-lg bg-indigo-500 px-2 py-1.5 text-white font-medium">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24"
               stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6m0 0v6m0-6h6m-6 0H6"/>
          </svg>
          Faire un transfert
        </Link>
      </div>
      <Tabs direction="horizontal">
        <Tab title="All">
          <TransferList origine={"transfer"}></TransferList>
        </Tab>
        <Tab title="Success">
          <TransferList valueFromParent = {"succeeded"}></TransferList>
        </Tab>
        <Tab title="Failed">
          <TransferList valueFromParent = {"failed"}></TransferList>
        </Tab>
      </Tabs>
    </div>
  );
}

export default Transfer;