import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getCustomer} from "../../services/api/getCustomer";
import {DebounceInput} from "react-debounce-input";
import {Button} from "@tremor/react";
import {HiArrowSmRight} from "react-icons/hi";
import {setCustomer} from "../../redux/features/customer/customerInputSlice";
import notification from "../../@yb/components/notification";

const InvoiceAddCustomer = ({onNext, width = 'w-1/2 mt-32 ', showNavigation= true}) => {

  const {selectedAccount} = useSelector((state) => state.account);
  const { customer } = useSelector(
    (state) => state.customerInput
  );
  const [customers, setCustomers] = React.useState([]);
  const [selectedCustomer, setSelectedCustomer] = React.useState(null);

  const dispatch = useDispatch();

  const searchCustomer = async (query) => {
    try {
      const c = await getCustomer(selectedAccount.id, query);
      setCustomers(c.data)
    } catch (e) {
      console.log(e);
      notification.error("Une erreur est survenue");
    }
  }

  return (
    <div className={`${width} 'flex flex-col mx-auto text-center'`}>
      <DebounceInput
        minLength={3}
        debounceTimeout={300}
        placeholder="Search for a customer"
        autoFocus type="text"
        onChange={async (e) => {
          await searchCustomer(e.target.value)
        }}
        className='w-full border-t-0 border-l-0 border-r-0 border-b-gray-300 focus:ring-0 focus:ring-offset-0 pl-0 mt-10 text-2xl focus:border-b-gray-300 focus:border-b-2 text-gray-300'/>

      <div className="mt-5">
        {customers.map((c, index) => (
          <div key={c.id} id={"user-"+index}
               onClick={() => {
                 setSelectedCustomer(c)
                 dispatch(setCustomer(c));
                 setCustomers([])
               }}
               className="flex items-center justify-between py-2 cursor-pointer hover:text-indigo-600 hover:font-bold hover:text-5xl transition duration-500">
            <span className="text-lg text-gray-700">
              {c.name}
            </span>
            <span className="text-lg text-gray-700">
              {c.email}
            </span>
          </div>
        ))}
      </div>

      <div>
        {customer && (
          <div>
            <div className="flex items-center justify-between py-2">
            <span className="text-lg text-gray-700">
              {customer.name}
            </span>
              <span className="text-lg text-gray-700">
              {customer.email}
            </span>
            </div>
          </div>
        )}
      </div>
      {showNavigation && (
        <div className="flex justify-end mt-20">
          <Button
            size="sm"
            importance="primary"
            text="Suivant"
            icon={HiArrowSmRight}
            iconPosition="right"
            disabled={!customer}
            onClick={() => onNext(2)}
          />
        </div>
      )}
    </div>
  );
}

export default InvoiceAddCustomer;