import React, { Fragment, useEffect, useRef, useState } from "react";
import { Transition, Dialog } from "@headlessui/react";
import AddProduct from "../../../pages/products/addProduct";
import { useDispatch, useSelector } from "react-redux";
import { NumericFormat } from 'react-number-format';
import ProductIcon from "../icons/productIcon";
import {
  addCartProducts,
  removeCartProduct,
  setCartProducts,
  setShowAddProductInput,
  setShowModal,
  updateCartProductQuantity,
} from "../../../redux/features/payment/createPaymentLinkSlice";
import { getProducts } from "../../../redux/features/product/productSlice";
import {Button} from "@tremor/react";
import {HiArrowSmRight, HiArrowSmLeft} from "react-icons/hi";

function useOuterClick(callback) {
  const callbackRef = useRef(); // initialize mutable ref, which stores callback
  const innerRef = useRef(); // returned to client, who marks "border" element

  // update cb on each render, so second useEffect has access to current value
  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);

    function handleClick(e) {
      if (
        innerRef.current &&
        callbackRef.current &&
        !innerRef.current.contains(e.target)
      )
        callbackRef.current(e);
    }
  }, []); // no dependencies -> stable click listener

  return innerRef; // convenience for client (doesn't need to init ref himself)
}

export default function ProductInput({onNext, showNavigation = true, width = "w-1/2 mt-32"}) {
  const [isOpen, setIsOpen] = useState(false);

  const { products } = useSelector((state) => state.product);
  const { cartProducts, showModal, showAddProductInput } = useSelector(
    (state) => state.paymentLink
  );
  const { selectedAccount } = useSelector((state) => state.account);

  useEffect(() => {
    dispatch(getProducts(selectedAccount.id));
  }, [selectedAccount]);

  const dispatch = useDispatch();

  const [query, setQuery] = useState("");

  const InitfilteredProduct =
    query === ""
      ? products
      : products.filter((p) =>
          p.name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  const filteredProduct = InitfilteredProduct.filter((x) => {
    return cartProducts.filter((y) => {
      return x.uuid === y?.product?.uuid;
    });
  });

  function closeModal() {
    setIsOpen(false);
    dispatch(getProducts(selectedAccount.id));
  }

  function openModal() {
    setIsOpen(true);
  }

  const addProduct = () => {
    return (
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="mt-2">
                    <AddProduct showNav={false} prev="/payment-link/create" />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const innerRef = useOuterClick((ev) => {
    dispatch(setShowModal(false));
  });

  return (
    <div className={`${width} flex flex-col mx-auto`}>
      {addProduct()}

      {(showAddProductInput || cartProducts.length === 0) && (
        <>
          <div className="text-center">
            <input
              type="text"
              className='border-t-0 border-l-0 border-r-0 border-b-gray-300 focus:ring-0 focus:ring-offset-0 pl-0 text-2xl focus:border-b-gray-300 focus:border-b-2 text-gray-300 w-full'
              placeholder="Search for a product"
              onChange={(event) => setQuery(event.target.value)}
              onClick={() => dispatch(setShowModal(true))}
            />
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            show={showModal}
          >
            <div className="border w-full mt-5  rounded-lg p-2 h-52 overflow-auto z-20">
              <button
                onClick={openModal}
                className="flex items-center text-sm py-1 w-full bg-transparent text-left outline-none border-2 border-transparent rounded text-indigo-500 font-medium  hover:bg-indigo-600 hover:text-white hover:border-transparent  focus:text-white focus:border-transparent focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 disabled:bg-gray-400/80 disabled:shadow-none disabled:cursor-not-allowed transition-colors duration-200"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 6v12m6-6H6"
                  />
                </svg>
                <span>add new product</span>
              </button>

              <div ref={innerRef} className="mt-1">
                {filteredProduct.map((product, key) => (
                  <div
                    key={key}
                    className="flex flex-col"
                    onClick={() => {
                      dispatch(addCartProducts({ product, quantity: 1 }));
                      dispatch(setShowAddProductInput(false));
                    }}
                  >
                    <span className="text-gray-500 text-sm">
                      {" "}
                      {product.name}
                    </span>
                    <NumericFormat
                      className="hover:bg-indigo-600 hover:text-white rounded px-2 cursor-pointer py-1 text-sm"
                      value={product.price}
                      displayType={"text"}
                      thousandSeparator=" "
                      prefix={"Eur "}
                    />
                  </div>
                ))}
              </div>
            </div>
          </Transition>
        </>

      )}

      {cartProducts.length > 0 && !showAddProductInput && (
        <button
          onClick={() => dispatch(setShowAddProductInput(true))}
          className="py-1 w-full text-sm bg-transparent text-left outline-none border-2 border-transparent rounded text-indigo-500 font-medium hover:text-black"
        >
          add another product
        </button>
      )}

      <div className="mt-5">
        {cartProducts.map((product, key) => (
          <div key={key} className="w-full border my-2 p-2 rounded-md relative">
            <button
              onClick={() => {
                dispatch(removeCartProduct(product.product.uuid));
              }}
              className="absolute -top-2 -right-3 p-0 w-6 h-6 rounded-full active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            <div className="flex flex-row items-center">
              <ProductIcon />
              <div className="flex flex-col pl-2">
                <span className="font-bold">{product.product.name}</span>
                <NumericFormat
                  className="text-gray-500 text-sm"
                  value={product.product.price}
                  displayType={"text"}
                  thousandSeparator=" "
                  prefix={"Eur "}
                />
              </div>
            </div>

            <div className="flex pt-2 items-center">
              <input
                type="number"
                className={`border-gray-300 rounded-lg bg-gray-50 border text-gray-900 w-12  px-2 py-1  outline-0`}
                value={product.quantity}
                onChange={(event) => {
                  dispatch(
                    updateCartProductQuantity({
                      uuid: product.product.uuid,
                      quantity: parseInt(event.target.value),
                    })
                  );
                }}
              />
              <span className="pl-2 text-sm">Quantité</span>
            </div>
          </div>
        ))}
      </div>


      {showNavigation && (
        <div className="flex justify-between">
          <Button
            size="sm"
            importance="primary"
            text="Précédent"
            icon={HiArrowSmLeft}
            onClick={() => onNext(1)}
          />
          <Button
            size="sm"
            importance="primary"
            text="Suivant"
            icon={HiArrowSmRight}
            iconPosition="right"
            disabled={cartProducts.length === 0}
            onClick={() => onNext(3)}
          />

        </div>
      )}
    </div>
  );
}
