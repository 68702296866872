import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";

export const fetchUser = createAsyncThunk(
    'auth/me',
    async (payload) => {
        return axios.get(`${process.env.REACT_APP_AUTH_URL}/v1/me`, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token")
            }
        })
            .then(res => res.data).catch(err => err);
    }
);

const meSlice = createSlice({
    name: 'me',
    initialState: {
        loading: false,
        error: null,
        data: {},
        isAuthenticated: false,
    },
    reducers: {
        setIsAuthenticated: (state, action) => {
            state.isAuthenticated = action.payload
        },
        setData: (state, action) => {
            state.data = action.payload
        },
        logout: (state, action) => {
            state.data = {}
            state.isAuthenticated = false
            localStorage.clear();


        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUser.pending, (state, action) => {
            state.loading = true;
            state.error = null;
            state.data = {};
        }).addCase(fetchUser.fulfilled, (state, action) => {

            state.loading = false;
            state.data = action.payload;
        }).addCase(fetchUser.rejected, (state, action) => {
            state.loading = true;
            state.error = action.payload;

        })
    }
});

export const {setIsAuthenticated, logout, setData} = meSlice.actions;
export default meSlice.reducer;