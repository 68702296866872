import React from 'react';
import {useSelector} from "react-redux";

function Accounts() {
    const store = useSelector(state => state.store);

    return (
        <div className=" mt-6">
            <h2 className="pb-4 text-3xl font-bold">Information</h2>
            <hr/>

            <div className="max-w-2xl">
                <div className="flex items-center justify-between py-4">
                    <span className="">name</span>
                    <span>{store.selectedStore.name}</span>
                </div>

                <div className="flex items-center justify-between py-4">
                    <span className="">url</span>
                    <span>{store.selectedStore.url}</span>
                </div>

                <div className="flex items-center justify-between py-4">
                    <span className="">address</span>
                    <span>{store.selectedStore.address}</span>
                </div>

                <div className="flex items-center justify-between py-4">
                    <span className="">phone</span>
                    <span>{store.selectedStore.phone}</span>
                </div>
            </div>

            <h2 className="py-6 mt-2 text-2xl font-bold">Information</h2>
            <hr/>
            <div>
                <div className="flex items-center justify-between py-4">
                    <span className="">uuid</span>
                    <span>{store.selectedStore.uuid}</span>
                </div>

                <div className="flex items-center justify-between py-4">
                    <span className="">Status</span>
                    <span>{store.selectedStore.isEnabled ? "Active" : "Suspend"}</span>
                </div>

                <div className="flex items-center justify-between py-4">
                    <span className="uppercase">Pays</span>
                    <span>{store.selectedStore.country.name}</span>
                </div>

                <div className="flex items-center justify-between py-4">
                    <span className="uppercase">Opérateur</span>
                    <span>{store.selectedStore.operator.name}</span>
                </div>
            </div>
        </div>
    );
}

export default Accounts;