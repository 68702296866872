import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import SystemLogin from "./pages/auth/login";
import Dashboard from "./pages/dashboard";
import Layout from "./layout";
import Payments from "./pages/payment";
import Developer from "./pages/developer";
import CreateAccount from "./pages/account/createAccount";
import Accounts from "./pages/account";
import SystemRegister from "./pages/auth/register";
import Customers from "./pages/customers/Customers";
import Balance from "./pages/balance/balance";
import Products from "./pages/products/products";
import Add from "./pages/invoices/Add";
import AddProduct from "./pages/products/addProduct";
import EditProduct from "./pages/products/editProduct";
import PaymentDetails from "./components/payments/payment/payment-details";
import CreatePaymentLink from "./pages/payment/payment-link/createPaymentLink";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Details from "./pages/invoices/Details";
import Edit from "./pages/invoices/Edit";
import ActivateAccount from "./pages/account/ActivateAccount";
import PaymentLinkDetails from "./components/payments/payment-link/paymentLinkDetails";
import CreateTransfer from "./pages/payment/transfer/createTransfer";

const routes = [
  {
    path: "/",
    Component: Dashboard,
    //redirect: "/payment",
  },
  {
    path: "/payment",
    Component: Payments,
  },
  {
    path: "/payment/:id",
    Component: PaymentDetails,
  },
  {
    path: "/payment-link/:id",
    Component: PaymentLinkDetails,
  },
  {
    path: "/developer",
    Component: Developer,
  },
  {
    path: "/products",
    Component: Products,
  },
  {
    path: "/balance",
    Component: Balance,
  },
  {
    path: "/customers",
    Component: Customers,
  },
  {
    path: "/account/create",
    Component: CreateAccount,
  },
  {
    path: "/account",
    Component: Accounts,
  },
  {
    path: "/auth/login",
    Component: SystemLogin,
  },
  {
    path: "/invoice/create",
    Component: Add,
  },
  {
    path: "/invoice/:id/details",
    Component: Details,
  },
  {
    path: "/invoice/:id/edit",
    Component: Edit,
  },
  {
    path: "/product/create",
    Component: AddProduct,
  },
  {
    path: "/transfer/create",
    Component: CreateTransfer,
  },
  {
    path: "/account/activate",
    Component: ActivateAccount,
  },
  {
    path: "/payment-link/create",
    Component: CreatePaymentLink,
  },
  {
    path: "/product/edit",
    Component: EditProduct,
  },
  {
    path: "/auth/register",
    Component: SystemRegister,
  },
];

const Router = () => {
  return (
    <Layout>
      <ToastContainer />
      <Routes>
        {routes.map(({ path, Component }) => (
          <Route
            key={path}
            path={path}
            element={
              <Suspense fallback={null}>
                <Component />
              </Suspense>
            }
          />
        ))}
      </Routes>
    </Layout>
  );
};

export default Router;
