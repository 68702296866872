import React from 'react'
import AccountSwitcher from "../../@yb/components/accountSwitcher";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {HiDotsHorizontal} from "react-icons/hi";
import Avatar from "../../@yb/components/avatar";
import {Switch} from '@headlessui/react'
import {useDispatch, useSelector} from "react-redux";
import {Toaster} from "react-hot-toast";
import {toggleEnvironment} from "../../redux/user";
import {logout} from "../../redux/features/auth/meSlice";
import {PURGE} from "redux-persist/es/constants";
import {setSuccess} from "../../redux/features/auth/loginSlice.js";
import CreateAccount from "../../pages/account/createAccount";
import {HiArrowSmRight} from "react-icons/hi";
import {Badge} from "@tremor/react";
import {persistor} from "../../redux/store";


function MyToggle() {
  const {selectedAccount} = useSelector(state => state.account);

  const dispatch = useDispatch()

  return (
    <Switch disabled
            checked={!selectedAccount.is_live}
            onChange={() => {
              dispatch(toggleEnvironment(!selectedAccount.is_live))
            }}
            className={`${!selectedAccount.is_live ? 'bg-blue-600' : 'bg-gray-200'
            } relative inline-flex h-6 w-11 items-center rounded-full`}
    >
            <span
              className={`${!selectedAccount.is_live ? 'translate-x-6' : 'translate-x-1'
              } inline-block h-4 w-4 transform rounded-full bg-white`}
            />
    </Switch>
  )
}


const MainLayout = ({children}) => {

  let location = useLocation();
  const navigate = useNavigate();
  const user = useSelector(state => state.user);
  const me = useSelector(state => state.me);
  const {accounts, selectedAccount} = useSelector(state => state.account);
  const dispatch = useDispatch()


  return (
    <div className="h-full">
      <Toaster/>
      {accounts.length >= 1 ? (
        <>
          <div className="bg-white fixed w-full z-50">
            <header className="border-b border-gray-200 space-y-2">
              <nav className="max-w-8xl px-10 mx-auto flex items-center justify-between pt-4">
                <div className="flex items-center space-x-3 ">
                  {/*<YabetooLogo className="h-7"/>*/}
                  <img src='/resources/logo/yb-icon.svg' className="h-6" alt="logo"/>
                  <svg viewBox="0 0 32 32" stroke="currentColor" className="h-8 w-8 text-gray-300">
                    <line x1="10" y1="28" x2="22" y2="4"/>
                  </svg>
                  <AccountSwitcher/>

                  {!selectedAccount.is_live && (
                    <Link to="/account/activate"
                          className="flex items-center justify-center rounded-full bg-indigo-100 px-2 py-1 bg-white text-sm font-medium text-indigo-600">
                      Activer mon compte
                      <HiArrowSmRight className="h-5 w-5  mt-0.5 ml-1"/>

                    </Link>
                  )}

                </div>
                {!selectedAccount.is_live && (
                  <div>
                    <Badge
                      text="test mode."
                      color="red"
                      size="sm"
                      icon={undefined}
                      tooltip="Activate your account to remove this banner."
                      marginTop="mt-0"/>
                  </div>
                )}
                <div className="flex items-center space-x-4">
                  <div className="flex items-center space-x-5">
                    <button type="button" onClick={async () => {
                      dispatch(logout());
                      dispatch(setSuccess(false));
                      navigate('/auth/login')
                      await persistor.purge();
                    }}
                            className="border border-gray-200 rounded px-3 py-1.5 text-sm leading-5 text-gray-500 hover:border-black transition ease-in-out duration-150">Log
                      out
                    </button>


                    <a href="https://docs.yabetoopay.com" target="_blank"
                       className="inline-block text-sm leading-5 text-gray-600 hover:text-black">Docs</a>

                  </div>
                  <Avatar
                    src={'https://ui-avatars.com/api/?background=random&name=' + me.data.first_name + "+" + me.data.last_name}/>
                </div>
              </nav>
              <div className="max-w-8xl px-10 mx-auto pt-4 pb-2.5">
                <nav className="-mb-pb flex items-center justify-between  space-x-5 text-sm leading-5">
                  <div className="flex space-x-5 text-sm leading-5">
                    <Link to="/">
                      <span
                        className={`${location.pathname === "/" ? 'border-black text-black' : 'border-transparent text-gray-500  hover:text-black transition ease-in-out duration-150'} border-b-2  px-0.5 py-3`}>
                        Dashboard
                      </span>
                    </Link>
                    <Link to="/payment">
                                            <span
                                              className={`${location.pathname === "/payment" ? 'border-black text-black' : 'border-transparent text-gray-500  hover:text-black transition ease-in-out duration-150'} border-b-2  px-0.5 py-3`}>Payments</span>
                    </Link>
                    {/* <Link to="/balance">
                                            <span
                                                className={`${location.pathname === "/balance" ? 'border-black text-black' : 'border-transparent text-gray-500  hover:text-black transition ease-in-out duration-150'} border-b-2  px-0.5 py-3`}>Balance</span>
                                        </Link>*/}
                    <Link to="/customers">
                      <span
                        className={`${location.pathname === "/customers" ? 'border-black text-black' : 'border-transparent text-gray-500  hover:text-black transition ease-in-out duration-150'} border-b-2  px-0.5 py-3`}>Customers</span>
                    </Link>
                    <Link to="/products">
                      <span
                        className={`${location.pathname === "/products" ? 'border-black text-black' : 'border-transparent text-gray-500  hover:text-black transition ease-in-out duration-150'} border-b-2  px-0.5 py-3`}>Products</span>
                    </Link>
                    <Link to="/.">
                      <span
                        className={`${location.pathname === "" ? 'border-black text-black' : 'border-transparent text-gray-500  hover:text-black transition ease-in-out duration-150'} border-b-2  px-0.5 py-3`}></span>
                    </Link>
                  </div>
                  <div className="flex items-center space-x-4">
                    <Link to="/developer">
                      <span
                        className={`${location.pathname === "/developer" ? 'border-black text-black' : 'border-transparent text-gray-500  hover:text-black transition ease-in-out duration-150'} border-b-2  px-0.5 py-3`}>Developer</span>
                    </Link>
                    {/*<div className="flex items-center space-x-2 text-sm leading-5 text-gray-500">
                      <span>Test mode</span>
                      <MyToggle/>
                    </div>*/}
                  </div>
                </nav>
              </div>
            </header>
          </div>
          <main className="h-full max-w-8xl mx-auto px-10 pt-28">
            {children}
          </main>
        </>

      ) : <CreateAccount/>}
    </div>
  )
}

export default MainLayout
