import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";

// login initial

export const loginUser = createAsyncThunk(
    'auth/login',
    async (payload) => {
        return axios.post(`${process.env.REACT_APP_AUTH_URL}/login`, payload)
            .then(res => res.data).catch(err => err);
    }
);

const loginSlice = createSlice({
    name: 'login',
    initialState: {
        loading: false,
        success: false,
        error: null,
        data: {},
    },
    reducers: {
        setSuccess: (state, action) => {
            state.success = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loginUser.pending, (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = null;
            state.data = {};
        }).addCase(loginUser.fulfilled, (state, action) => {
            state.loading = false;
            const data = action.payload;

            if(data.token === undefined) {
                state.error = data.response.data;
            } else {
                state.data = data;
                localStorage.setItem('token', data.token.token);
                state.success = true;
            }

        }).addCase(loginUser.rejected, (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = action.payload;
        })
    }
});

export const {setSuccess} = loginSlice.actions;
export default loginSlice.reducer;