import {Badge} from "@tremor/react";
import React from "react";

const getStatusBadge = (status) => {
  switch (status) {
    case 'succeeded':
      return (
        <Badge
          text={status}
          color="green"
          size="sm"
          icon={undefined}
          tooltip=""
          marginTop="mt-0" />
      );
    case 'failed':
      return (
        <Badge
          text={status}
          color="red"
          size="sm"
          icon={undefined}
          tooltip=""
          marginTop="mt-0" />
      );
    case 'incomplete':
      return (
        <Badge
          text={status}
          color="gray"
          size="sm"
          icon={undefined}
          tooltip=""
          marginTop="mt-0" />
      );
      default:
        return (
          <Badge
            text={status}
            color="blue"
            size="sm"
            icon={undefined}
            tooltip=""
            marginTop="mt-0" />
        );
  }
}

export default getStatusBadge;