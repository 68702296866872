import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import notification from "../../../../@yb/components/notification";

export const duplicatedInvoice = createAsyncThunk(
  "invoice/duplicatedInvoice",
  async (invoiceId) => {
    const dupDatas = "duplication";
    return axios
      .post(
        `${process.env.REACT_APP_YABETOO_INVOICE}/invoice/${invoiceId}/duplicate`,
        dupDatas,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data)
      .catch((e) => e.response);
  }
);

const duplicatedSlice = createSlice({
  name: "duplicatedInvoice",
  initialState: {
    invoice: null,
    loading: false,
    error: null,
  },

  extraReducers: {
    [duplicatedInvoice.pending]: (state, action) => {
      state.loading = true;
    },
    [duplicatedInvoice.fulfilled]: (state, action) => {
      state.loading = false;
      state.invoice = action?.payload?.data;
    },
    [duplicatedInvoice.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      notification.error(action?.payload?.message);
    },
  },
});

export default duplicatedSlice.reducer;
