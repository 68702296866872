import {MdOutlineClose} from "react-icons/md";
import React, {useState} from "react";
import { NumericFormat } from 'react-number-format';
import generateId from "../../utils/randomId";
import axios from "axios";
import {useSelector} from "react-redux";
import notification from "../../@yb/components/notification";
import {useNavigate} from "react-router-dom";

const EditProduct = () => {

  const [name, setName] = useState("");
  const [price, setPrice] = useState(0);
  const [description, setDescription] = useState("");
  const [images, setImage] = useState([]);
  const [reference, setReference] = useState(`YAB-${generateId(15)}`);

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const {selectedAccount} = useSelector(state => state.account);

  const navigate = useNavigate();

  const saveProduct = async (e) => {
    e.preventDefault();
    setLoading(true);
    axios.post(`${process.env.REACT_APP_STORE_URL}/product`, {
      name,
      price,
      description,
      reference,
      accountId: selectedAccount.id,
      images
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).then(res => {
      notification.success("Produit ajouté avec succès");
      navigate("/products");
    }).catch(err => {
      notification.error("Une erreur est survenue lors de la création du produit");
      setErrors(err.response.data.errors);
      console.log(err)
    }).finally(() => {
      setLoading(false);
    })
  }

  return (
    <form onSubmit={saveProduct}>
      <main>
        <div className="border border-b h-16 w-full flex justify-between items-center px-6">
          <div className="flex">
            <MdOutlineClose className="h-6 w-6 text-gray-600"/>
            <div className="border border-l h-6 w-0 ml-5"></div>
            <span className="ml-5">Ajouter un produit</span>
          </div>
          <div>
            <div>
              <button type='submit'
                      className="flex items-center justify-center text-center w-full bg-blue-700 rounded text-white py-1 px-3 font-medium rounded-lg">
                {loading ? <svg className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                          strokeWidth="4"/>
                  <path className="opacity-75" fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                </svg> : <span className="font-medium subpixel-antialiased">Enregistrer le produit </span>}


              </button>
            </div>
          </div>

        </div>
        <div className="max-w-2xl mx-auto pt-10">
          <p className="font-bold text-2xl pb-6">Information sur le produit</p>
          <p className="font-semibold">Détails du produit</p>
          <div className="flex justify-between pt-10">
            <div className="w-1/2">
              <label htmlFor="name"
                     className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Nom</label>
              <input required id="name" onChange={(event) => setName(event.target.value)}
                     className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"/>

              <label htmlFor="name"
                     className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400 pt-5">Reference</label>
              <input required id="name" value={reference} onChange={(event) => setReference(event.target.value)}
                     className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"/>

              <label htmlFor="name"
                     className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400 pt-5">Description</label>
              <textarea id="name" onChange={(event) => setDescription(event.target.value)} rows={5}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"/>

            </div>

            <div className="flex items-center justify-center w-1/2 px-10 py-7">
              <label htmlFor="dropzone-file"
                     className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 ">
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <svg className="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                       xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                  </svg>
                  <p className="mb-2 text-sm text-gray-500 "><span className="font-semibold">Click to upload</span> or
                    drag and drop</p>
                  <p className="text-xs text-gray-500 ">SVG, PNG, JPG (MAX. 800x400px)</p>
                </div>
                <input id="dropzone-file" type="file" className="hidden"/>
              </label>
            </div>
          </div>

          <p className="font-bold text-2xl pt-6">Informations tarifaires</p>
          <div className="pt-10">
            <NumericFormat onValueChange={(event) => setPrice(event.floatValue)} thousandSeparator={true} prefix={'$'}
                          allowNegative={false}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-1/2 p-2.5"/>
          </div>
        </div>
      </main>
    </form>
  )
}

export default EditProduct;