import React from 'react';
import {
  setDescription, setIsLimited, setLimitMax, setLimitMin,
  setTitle
} from "../../../../redux/features/payment/createPaymentLinkSlice";
import {useDispatch, useSelector} from "react-redux";
import InputField from "../../../../@yb/components/Input/InputField";

function PaymentTapType2() {

  const {title, description, isLimited, limits} = useSelector(state => state.paymentLink)
  const dispatch = useDispatch()

  return (
    <div className="py-5">


        <div className="flex items-center justify-center w-full py-7">
          <label htmlFor="dropzone-file"
                 className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 ">
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <svg className="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                   xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
              </svg>
              <p className="mb-2 text-sm text-gray-500 text-center"><span className="font-semibold">Click to upload</span> or
                drag and drop</p>
            </div>
            <input id="dropzone-file" type="file" className="hidden" onChange={(event)  => {
              console.log(event.target.files[0])
            }}/>
          </label>
        </div>

        <div className="w-full">

          <InputField label="Titre" shadow={true} onChange={(v) => dispatch(setTitle(v))} size="xs" type="text" value={title} placeholder="Ajouter un titre à votre page de paiement"/>
          <InputField type='textarea' shadow={true} label="Description" onChange={(v) => dispatch(setDescription(v))} value={description} rows={5} />

        </div>



      <div className="flex items-center  mt-5">
        <input id="default-checkbox" type="checkbox" checked={isLimited} value={isLimited}
               onChange={(event) => {
                 dispatch(setIsLimited(event.target.checked))
               }} className="w-4 h-4 shadow-sm border border-gray-200 rounded ring-0"/>
        <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium ">Set limit</label>
      </div>

      {isLimited && (
        <div className="flex flex-row items-center justify-around">
          <div className="ml-5">
            <InputField onChange={(v) => dispatch(setLimitMin(v))} size="xs" labelSize="xs" label="Minimum amount" shadow={true} type="number" value={limits.min} />
          </div>
          <div className="ml-1">
            <InputField onChange={(v) => dispatch(setLimitMax(v))} size="xs" labelSize="xs" label="Maximun amount" shadow={true} type="number" value={limits.max} />
          </div>
        </div>
      )}

    </div>
  );
}

export default PaymentTapType2;