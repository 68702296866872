import React from 'react';
import getBusinessType from "../../services/getBusinessType";
import {useDispatch, useSelector} from "react-redux";
import {
  setBusinessDescription,
  setBusinessName, setBusinessPhoneNumber,
  setIndustry,
  setBusinessWebsite, setBusinessVat, setCurrentStep
} from "../../redux/features/account/activateAcountSlice";
import getIndustries from "../../services/getIndustries";
import PhoneInputField from "../../@yb/components/PhoneInput";
import InputField from "../../@yb/components/Input/InputField";
import {Button} from "../../@yb/components/Button/Button";

function BusinessDetails() {
  const [businessTypes, setBusinessTypes] = React.useState([]);
  const dispatch = useDispatch();

  const {businessDetail} = useSelector((state) => state.activateAccount);

  const fetchBusinessType = async () => {
    const response = await getIndustries();
    setBusinessTypes(response);
  }

  React.useEffect(() => {
    fetchBusinessType();
  }, []);

  return (
    <div>
      <p className="text-3xl">Let’s start with some basics</p>
      <span>Choose your location and business type to get started.</span>

      <div className="mt-2">
        <InputField value={businessDetail.vat} label="TVA" onChange={(e) => dispatch(setBusinessVat(e))} placeholder="Tva" optional={true}  />
      </div>

      <div className="flex flex-col items-start w-full mt-5">
        <span className="mb-2">Industrie</span>
        <select value={businessDetail.industryId} onChange={(e) => dispatch(setIndustry(e.target.value))} className="focus:outline-0 shadow-sm block pr-12 py-2 border-gray-300 rounded-lg border w-full h-12">
          <option value={null}>Selectionner votre industrie</option>
          {businessTypes.map((businessType) => (
            <optgroup  key={businessType.id} label={businessType.name}>
              {businessType.industries.map((industry) => (
                <option key={industry.id} value={industry.id}>
                  {industry.name}
                </option>
              ))}
            </optgroup>
          ))}
        </select>
      </div>

      <div className="mt-5">
        <InputField value={businessDetail.businessName} label="Business name" onChange={(e) => dispatch(setBusinessName(e))} placeholder="Business name"  />
      </div>

      <div className="mt-5">
        <InputField value={businessDetail.website} label="Website" onChange={(e) => dispatch(setBusinessWebsite(e))} placeholder="Business name"  />
      </div>

      <div className="mt-5">
        <InputField value={businessDetail.description} label="Product description" type="textarea" onChange={(e) => dispatch(setBusinessDescription(e))} placeholder="Business name"  />
      </div>

      <div className="mt-5">
        <hr/>
        <span>Le numéro qui sera afficher dans les informations publique de votre business</span>
        <PhoneInputField defaultValue={businessDetail.phoneNumber}  label="Phone number" onChange={(e) => dispatch(setBusinessPhoneNumber(e))}/>
      </div>

      <div className="mt-6">
        <Button block={true} size="sm" label="Continuer" onClick={()=> dispatch(setCurrentStep({item : 1, step: 0}))} />
      </div>

    </div>
  );
}

export default BusinessDetails;