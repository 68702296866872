import React, {useState} from "react";
import StoreIcon from "./storeIcon";
import { HiOutlinePlus, HiSelector} from "react-icons/hi";
import Transition from "./transition";
import {Link} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {setSelectedAccount} from "../../redux/features/account/accountSlice";

function AccountSwitcher() {
    const [isOpen, setIsOpen] = useState(false);
    const {accounts, selectedAccount} = useSelector(state => state.account);
    const dispatch = useDispatch();
    return (
        <div className="relative z-50" onMouseLeave={() => setIsOpen(false)}>
            <span className="inline-flex items-center space-x-2 text-sm leading-5 font-medium">
               <span><StoreIcon/></span>
               <span>{selectedAccount?.name}</span>
               <button onClick={() => setIsOpen(!isOpen)} type="button"
                       className={`${isOpen ? 'border-gary-200 text-black' : 'border-transparent text-gray-400 '}inline-flex items-center border hover:border-gray-200 hover:bg-gray-50 transition ease-in-out duration-150 rounded p-0.5`}>
                   <HiSelector className="h-5 w-5"/>
               </button>
            </span>
            <Transition
                show={isOpen}
                leave="transition ease-in duration-100 transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-2">
                <div
                    className="absolute w-56 rounded-md bg-white border divide-y divide-gray-200 shadow-lg overflow-hidden">
                    <div className="py-2">
                        <div className="pt-3 px-4 pb-2 text-sm leading-5 uppercase tracking-wide text-gray-500">
                            Mes comptes
                        </div>
                        <ul>
                            {accounts.map((s, index) => (
                                <li key={index} className="px-4 py-3 bg-gray-60">
                                    <div className="flex items-center justify-between space-x-4">
                                            <span onClick={() => {
                                                dispatch(setSelectedAccount(s));
                                            }}
                                                  className="flex items-center space-x-4 text-sm leading-5 text-gray-500 hover:text-black cursor-pointer">
                                                <StoreIcon/>
                                                <span>{s.name}</span>
                                            </span>
                                        {/*  <div>
                                            <Link to="/account"><span><HiOutlineCog className="h-5 w-5 text-gray-300 hover:text-black"/></span></Link>
                                        </div>*/}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="py-2">
                        <Link to="/account/create">
                            <span
                                className="px-4 py-3 flex items-center justify-between text-sm leading-5 text-gray-500 hover:text-black cursor-pointer">
                                <span>Ajouter un compte</span>
                                <HiOutlinePlus className="h-4 w-4"/>
                            </span>
                        </Link>
                    </div>
                </div>
            </Transition>
        </div>
    );
}

export default AccountSwitcher;