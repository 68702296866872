import axios from "axios";
import Constants from "../../@yb/utils/constants";

export const getPaymentLink = async (id, selectedAccount) => {
  return await axios
    .get(
      `${Constants.getPaymentUrl(selectedAccount)}/api/v1/payment-links/${id}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    ).then(res => res.data);
}