import React from 'react';
import Overview from '../../components/customers/overview'
import {Tab, Tabs} from "../../@yb/components/Tabs/Tabs";


function Customers() {
    return (
      <>
          <Tabs
            title="Customers"
          >
              <Tab title="Overview">
                  <Overview />
              </Tab>
          </Tabs>
      </>
    );
}

export default Customers;