import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import notification from "../../../../@yb/components/notification";

export const deleteInvoice = createAsyncThunk(
  "invoice/deleteInvoice",
  async (invoiceId) => {
    return axios
      .delete(`${process.env.REACT_APP_YABETOO_INVOICE}/invoice/${invoiceId}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => res.data)
      .catch((e) => e);
  }
);

const deleteSlice = createSlice({
  name: "deleteInvoice",
  initialState: {
    invoice: null,
    loading: false,
    error: null,
  },

  extraReducers: {
    [deleteInvoice.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteInvoice.fulfilled]: (state, action) => {
      state.loading = false;
      state.invoice = action?.payload?.data;
      notification.success(action?.payload?.message);
    },
    [deleteInvoice.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      notification.error(action?.payload?.message);
    },
  },
});

export default deleteSlice.reducer;
