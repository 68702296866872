import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";

export const getCountries = createAsyncThunk(
    'country/getCountries',
    async () => {
        return axios.get(`${process.env.REACT_APP_AUTH_URL}/v1/countries`, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        }).then(res => res.data);
    }
);

const postSlice = createSlice({
    name: 'country',
    initialState: {
        countries: [],
        loading: false,
        error: null,
        selectedCountry: null,
        selectedOperator: null,
    },
    reducers: {
        setSelectedCountry: (state, action) => {
            state.selectedCountry = action.payload;
        },
        setSelectedOperator: (state, action) => {
            state.selectedOperator = action.payload;
        }

    },
    extraReducers: {
        [getCountries.pending]: (state, action) => {
            state.loading = true;
        },
        [getCountries.fulfilled]: (state, action) => {
            state.loading = false;
            state.countries = action.payload;
        },
        [getCountries.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});
export const {setSelectedCountry, setSelectedOperator} = postSlice.actions;
export default postSlice.reducer;