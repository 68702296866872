import React, {useEffect, useState} from 'react';
import axios from "axios";
import moment from "moment/moment";
import {Link} from "react-router-dom";
import Constants from "../../../@yb/utils/constants";
import {useSelector} from "react-redux";
import getStatusBadge from "../../../@yb/utils/getStatusBadge"; // eslint-disable-line import/no-webpack-loader-syntax

function PaymentDetails() {
  const [payment, setPayment] = useState({});
  const {selectedAccount} = useSelector(state => state.account);

  const getPayment = async () => {
    const intent_id = window.location.pathname.split('/')[2];
    axios.get(`${Constants.getPaymentUrl(selectedAccount)}/api/v1/payment-intents/${intent_id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).then(function (response) {
      setPayment(response.data);
    }).catch(function (error) {
      console.log(error);
    });
  }

  useEffect(() => {
    getPayment();
  }, []);

  function copyToClipboard() {
    navigator.clipboard.writeText(payment.intent_id);
    // setClipboard(true);
  }

  return (
    <div className="flex flex-col mt-6">
      <div className="mb-5 w-32">
        <Link to="/payment" className="inline-block rounded-full border border-indigo-600 bg-indigo-600 p-3 text-white hover:bg-transparent hover:text-indigo-600 focus:outline-none focus:ring active:text-indigo-500">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 pr-1" fill="none"
               viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M10 19l-7-7m0 0l7-7m-7 7h18"/>
          </svg>
          <span className="sr-only"> Retour </span>

        </Link>
      </div>
      <div className="flex flex-row justify-between mb-4 items-center">
        <div className="flex flex-col">
          <div className="flex flex-row pb-1 text-gray-600 items-center">
            <svg xmlns="http://www.w3.org/2000/svg"
                 className="h-8 w-8 mr-2" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd"
                    d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z"
                    clipRule="evenodd"/>
            </svg>
            <span>PAIEMENT</span>
          </div>
          <div className="flex flex-row items-center">
            <span className="font-bold text-2xl">{payment?.amount}</span>
            <span className="text-gray-600 ml-1 mr-2 text-2xl">{selectedAccount.country.currency.code.toUpperCase() ?? "EUR"}</span>
            {getStatusBadge(payment.status)}
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-row pb-3 text-gray-600">
            <span>{payment.intent_id}</span>
            <svg onClick={copyToClipboard()} xmlns="http://www.w3.org/2000/svg"
                 className="h-5 w-5 cursor-pointer"
                 viewBox="0 0 20 20"
                 fill="currentColor">
              <path d="M8 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"/>
              <path
                d="M6 3a2 2 0 00-2 2v11a2 2 0 002 2h8a2 2 0 002-2V5a2 2 0 00-2-2 3 3 0 01-3 3H9a3 3 0 01-3-3z"/>
            </svg>
          </div>
          <div className="flex flex-row items-center justify-end gap-5">
            <button
              className="flex items-center rounded-lg border px-2 py-1.5 bg-white text-sm font-medium">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 pr-1" fill="none"
                   viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M10 19l-7-7m0 0l7-7m-7 7h18"/>
              </svg>
              Rembourser...
            </button>
            <button
              className="flex items-center rounded-lg border px-2 py-1.5 bg-white text-sm font-medium">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24"
                   stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round"
                      d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <hr/>
      <div className="flex flex-row mt-4 mb-10 text-gray-600">
        <div className="flex flex-col pr-4 border-r">
          <span>Date</span>
          <span>{moment(payment.created_at).format("LLL")}</span>
        </div>
        <div className="flex flex-col px-4 border-r">
          <span>Client</span>
          <div className="flex flex-row items-center">
            {payment.customer ? <div>
              <span className="mr-2">{payment.customer.name?? "Inconnu"}</span>
              <span className="bg-gray-300 rounded px-1 text-sm"> {payment.customer.email ?? "Invité"}</span>

            </div> : (
              <div>
                <span className="mr-2">Inconnu</span>
                <span className="bg-gray-300 rounded px-1 text-sm"> Invité</span>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col px-4">
          <span>Moyen de paiement</span>
          <div className="flex flex-row items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                 stroke="currentColor" className="h-5 w-5 mr-1">
              <path strokeLinecap="round" strokeLinejoin="round"
                    d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"/>
            </svg>
            {payment.event?.charges.length ?
              <div>
                <span>{payment.event?.charges[payment.event?.charges.length - 1].paymentMethod?.operator}&nbsp;</span>
                <span>{payment.event?.charges[payment.event?.charges.length - 1].paymentMethod?.msisdn}</span>
              </div>
              : <span className="text-sm">Inconnu</span>
            }
          </div>
        </div>
      </div>
      <div className="flex flex-col mb-10">
        <div className="flex flex-row justify-between items-center mb-2">
          <span className="font-bold text-xl">Chronologie</span>
          <button
            className="flex items-center rounded-lg border px-2 py-1.5 bg-white text-sm font-medium">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6m0 0v6m0-6h6m-6 0H6"/>
            </svg>
            Ajouter une note
          </button>
        </div>
        <hr/>
        <div className="flex flex-col mt-3 mb-3">
          <ol className="relative border-l border-gray-200 dark:border-gray-700">
            {payment.event?.charges.map((charge, index) => (
              <li className="mb-4 ml-6">
                {charge.status === "succeeded" ?
                  <span
                    className="flex absolute -left-3 justify-center items-center w-6 h-6 bg-green-200 rounded-full ring-8 ring-white">
                                        <svg aria-hidden="true" className="w-3 h-3 text-green-600" fill="currentColor"
                                             viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd"
                                                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                                                  clip-rule="evenodd"></path></svg>
                                    </span>
                  : charge.status === "failed" || "expired" ? <span
                    className="flex absolute -left-3 justify-center items-center w-6 h-6 bg-red-200 rounded-full ring-8 ring-white">
                                        <svg aria-hidden="true" className="w-3 h-3 text-red-700" fill="currentColor"
                                             viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd"
                                                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                                                  clip-rule="evenodd"></path></svg>
                                    </span> : null
                }
                <time
                  className="mb-1 text-sm font-normal leading-none text-gray-400">{moment(charge.created_at).format("LLL")}</time>
                {charge.failure_message ?
                  <h3 className="text-lg font-semibold text-gray-900">{charge.failure_message}</h3>
                  : <h3 className="text-lg font-semibold text-gray-900">Paiement réussi</h3>
                }
              </li>
            ))}
            <li className="ml-6">
                            <span
                              className="flex absolute -left-3 justify-center items-center w-6 h-6 bg-gray-200 rounded-full ring-8 ring-white">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3 text-gray-600"
                                     fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M12 7.5a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z"/>
                                    <path fillRule="evenodd"
                                          d="M1.5 4.875C1.5 3.839 2.34 3 3.375 3h17.25c1.035 0 1.875.84 1.875 1.875v9.75c0 1.036-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 011.5 14.625v-9.75zM8.25 9.75a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0zM18.75 9a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75V9.75a.75.75 0 00-.75-.75h-.008zM4.5 9.75A.75.75 0 015.25 9h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H5.25a.75.75 0 01-.75-.75V9.75z"
                                          clipRule="evenodd"/>
                                    <path
                                      d="M2.25 18a.75.75 0 000 1.5c5.4 0 10.63.722 15.6 2.075 1.19.324 2.4-.558 2.4-1.82V18.75a.75.75 0 00-.75-.75H2.25z"/>
                                </svg>

                            </span>
              <time
                className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">{moment(payment.created_at).format("LLL")}</time>
              <h3 className="text-lg font-semibold text-gray-900">Paiement démarré</h3>
            </li>
          </ol>
        </div>
        <hr/>
      </div>
      <div className="flex flex-col mb-8">
        <div className="font-bold text-xl mb-2">Moyen de paiement</div>
        <hr/>
        <div className="flex flex-col mt-3 mb-6 max-w-md">
          <div className="flex flex-row items-center">
            <span className="flex flex-auto text-sm">Opérateur</span>
            {payment.event?.charges.length ?
              <span
                className="text-sm">{payment.event?.charges[payment.event?.charges.length - 1]?.paymentMethod?.operator}</span>
              : <span className="text-sm">Inconnu</span>
            }
          </div>
          <div className="flex flex-row items-center">
            <span className="flex flex-auto text-sm">Pays</span>
            {payment.event?.charges.length ?
              <span
                className="text-sm">{payment.event?.charges[payment.event?.charges.length - 1]?.paymentMethod?.country}</span>
              : <span className="text-sm">Inconnu</span>
            }
          </div>
          <div className="flex flex-row items-center">
            <span className="flex flex-auto text-sm">Numéro</span>
            {payment.event?.charges.length ?
              <span
                className="text-sm">{payment.event?.charges[payment.event?.charges.length - 1]?.paymentMethod?.msisdn}</span>
              : <span className="text-sm">Inconnu</span>
            }
          </div>

        </div>
      </div>

      <div className="flex flex-col mb-8">
        <div className="font-bold text-xl mb-2">Connexions</div>
        <hr/>
        <div className="flex flex-col mt-3 mb-6 max-w-md">
          <div className="flex flex-row items-center">
            <span className="flex flex-auto text-sm">Paiement le plus récent</span>
            <span className="text-sm">{payment.charge_id}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentDetails;