import React, {Fragment} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {MdOutlineClose} from "react-icons/md";

import {useDispatch, useSelector} from "react-redux";
import {Listbox, Transition} from "@headlessui/react";
import {ChevronUpDownIcon} from "@heroicons/react/20/solid";
import {Tab, Tabs} from "../../../@yb/components/Tabs/Tabs";

import {
  createPaymentLink,
  paymentTypes,
  resetCreatePaymentLink,
  setActiveTab, setSelectedType,
} from "../../../redux/features/payment/createPaymentLinkSlice";
import CheckoutPreview from "../../../components/payments/payment-link/checkoutPreview";
import ConfirmPagePreview from "../../../components/payments/payment-link/confirmPagePreview";
import ConfirmTab from "../../../components/payments/payment-link/confirmTab";
import PaymentTab from "../../../components/payments/payment-link/payment-tab/paymentTab";
import notification from "../../../@yb/components/notification";
import createCommand from "../../../services/createCommand";
import generateId from "../../../utils/randomId";

function CreatePaymentLink(props) {


  const {activeTab, selectedType, loading} = useSelector(state => state.paymentLink)
  const {selectedAccount} = useSelector(state => state.account)
  const data = useSelector(state => state.paymentLink)

  const dispatch = useDispatch()
  const navigate = useNavigate()


  const selectType = () => {
    return <div className='w-70'>
      <Listbox value={selectedType} onChange={(e) => {
        dispatch(setSelectedType(e))
      }}>
        <div className="relative mt-3">
          <Listbox.Button
            className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-sm border focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
            <span className="block truncate">{selectedType.name}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              className="absolute mt-1 max-h-70 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {paymentTypes.map((person, personIdx) => (
                <Listbox.Option
                  key={personIdx}
                  className={({active}) =>
                    `relative cursor-default select-none py-2 px-2 cursor-pointer ${
                      active ? 'bg-gray-100 ' : 'text-gray-900'
                    }`
                  }
                  value={person}
                >
                  {({selected}) => (
                    <>
                      <div className='flex items-start'>
                        <svg aria-hidden="true" className="text-gray-500 px-1" fill='currentColor' height="26"
                             width="26" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M13.788 3.119a1.04 1.04 0 0 1-.31.283L8.5 6.362a.97.97 0 0 1-.998 0l-4.98-2.96a1.04 1.04 0 0 1-.309-.283L6.99.279a1.97 1.97 0 0 1 2.02 0zm1.194 1.647c.012.09.018.182.018.274v5.92c0 .743-.385 1.43-1.01 1.802l-4.98 2.96a1.97 1.97 0 0 1-2.02 0l-4.98-2.96A2.092 2.092 0 0 1 1 10.96V5.04c0-.092.006-.184.018-.274.147.133.308.252.481.355l4.98 2.96a2.97 2.97 0 0 0 3.042 0l4.98-2.96c.173-.103.334-.222.481-.355z"
                            fillRule="evenodd"></path>
                        </svg>
                        <div>
                          <span
                            className={`block truncate text-md ${selected ? 'font-medium' : 'font-normal'}`}>{person.name}</span>
                          <span className="text-sm text-gray-400 pt-1">{person.description}</span>
                        </div>


                      </div>


                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  }

  const createLink = async () => {
    const secretKey = selectedAccount.is_live ? selectedAccount.sk_live : selectedAccount.sk_test
    let createData = {...data, secretKey, selectedAccount}
    if (data.selectedType.type === 1 && data.cartProducts.length === 0) {
      notification.error('Please add products to cart')
      return;
    }

    if (data.selectedType.type === 2 && data.title === "") {
      notification.error('Please add title')
      return;
    }

    let command = null;

    if (data.selectedType.type === 1){
      command = await createCommand({
        cartProducts: data.cartProducts,
        accountId: selectedAccount.id,
        commandId: `YAB-${generateId(15)}`.toUpperCase(),
      })
    }

    createData = {...createData, commandId: command?.uuid}

    dispatch(createPaymentLink(createData)).then(res => {
       if(res.type === "payment/createPaymentLink/fulfilled"){
         navigate('/payment')
         notification.success("Payment link created successfully");
       }
     })
  }

  return (

    <main className='bg-white h-full'>
      <div className="border-b h-16 w-full flex justify-between items-center px-6 fixed z-10 bg-white">
        <div className="flex">
          <Link to="/payment" onClick={() => dispatch(resetCreatePaymentLink())}
                className="text-gray-500 hover:text-gray-700">
            <MdOutlineClose className="h-6 w-6 text-gray-600"/>
          </Link>
          <div className="border border-l h-6 w-0 ml-5"></div>
          <span className="ml-5">Create a payment link</span>
        </div>
        <div>
          <div>
            <button type='button' onClick={() => createLink()}
                    className="flex items-center justify-center text-center w-full bg-blue-700 rounded text-white py-1 px-3 font-medium rounded-lg">
              {loading ? <svg className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                        strokeWidth="4"/>
                <path className="opacity-75" fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
              </svg> : <span className="font-medium subpixel-antialiased">Create link</span>}


            </button>
          </div>
        </div>

      </div>
      <div className="h-full w-full h-full pt-20">
        <div className='w-1/2 mx-auto  h-full bg-white'>
          <div className="w-2/4 mx-auto pt-5 h-full">
            <span className='font-semibold'>Select type</span>
            {selectType()}

            <div className="pt-5">
              <Tabs direction='horizontal' active={activeTab} onChange={(key) => dispatch(setActiveTab(key))}>
                <Tab title="Payment page">
                  <PaymentTab/>
                </Tab>
                <Tab title="Confirmation page">
                  <ConfirmTab/>
                </Tab>
              </Tabs>
            </div>

          </div>
        </div>
       {/* <div className='w-1/2 bg-gray-50 h-full'>
          <div className="py-5 mx-16 h-full">
            <span className="font-bold text-2xl">Preview</span>
            {activeTab === ".0" ? <CheckoutPreview/> : <ConfirmPagePreview/>}
          </div>
        </div>*/}

      </div>
    </main>

  );
}

export default CreatePaymentLink;