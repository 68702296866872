import React from 'react';
import ApiKey from "../../components/developer/apiKey";
import {Tab, Tabs} from "../../@yb/components/Tabs/Tabs";


function Developer() {
    return (
      <>
          <Tabs
            title="Developer"
            >
              <Tab title="API Keys">
                  <ApiKey />
              </Tab>
          </Tabs>
      </>
    );
}

export default Developer;