import React from 'react';
import getBusinessType from "../../services/getBusinessType";
import {useDispatch, useSelector} from "react-redux";
import {setBusinessType, setCurrentStep} from "../../redux/features/account/activateAcountSlice";
import {Button} from "../../@yb/components/Button/Button";

function BusinessType() {
  const [businessTypes, setBusinessTypes] = React.useState([]);
  const {selectedAccount} = useSelector(state => state.account);
  const dispatch = useDispatch();

  const {businessType} = useSelector((state) => state.activateAccount);

  const fetchBusinessType = async () => {
    const response = await getBusinessType();
    setBusinessTypes(response);
  }

  React.useEffect(() => {
    fetchBusinessType();
  }, []);

  return (
    <div>
      <p className="text-3xl">Let’s start with some basics</p>
      <span>Choose your location and business type to get started.</span>

      <div className="flex flex-col items-start w-full my-5">
        <span className="mb-2">Business location</span>
        <select value={businessType} disabled className="focus:outline-0 shadow-sm h-12 block pr-12 border-gray-300 rounded-lg border w-full">
          <option value={selectedAccount?.country?.code}>{selectedAccount?.country?.name}</option>
        </select>
      </div>

      <div className="flex flex-col items-start w-full mt-5">
        <span className="mb-2">Type of business</span>
        <select value={businessType} onChange={(e) => dispatch(setBusinessType(e.target.value))} className="focus:outline-0 shadow-sm block h-12 pr-12 border-gray-300 rounded-lg border w-full">
          <option value={null}>Select your business</option>
          {businessTypes.map((businessType) => (
            <option  key={businessType.id} value={businessType.id}>
              {businessType.name}
            </option>
          ))}
        </select>
      </div>

     <div className="mt-6">
       <Button block={true} size="sm" label="Continuer" onClick={()=> dispatch(setCurrentStep({item : 0, step: 1}))} />
     </div>

    </div>
  );
}

export default BusinessType;