import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";

export const getOperators = createAsyncThunk(
    'operator/getOperators',
    async () => {
        return axios.get(`${process.env.REACT_APP_STORE_URL}/operators`, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        }).then(res => res.data);
    }
);

const operatorSlice = createSlice({
    name: 'operator',
    initialState: {
        operators: [],
        loading: false,
        error: null,
        selectedOperator: null,
    },
    reducers: {
        setSelectedOperator: (state, action) => {
            state.selectedOperator = action.payload;
        },

    },
    extraReducers: {
        [getOperators.pending]: (state, action) => {
            state.loading = true;
        },
        [getOperators.fulfilled]: (state, action) => {
            state.loading = false;
            state.operators = action.payload;
        },
        [getOperators.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});
export const {setSelectedOperator} = operatorSlice.actions;
export default operatorSlice.reducer;