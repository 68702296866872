import React from 'react';

function AccountSideBar({items, current = {item: 1, step: 0}, onStepChange}) {
  return (
    <div className="h-full flex">
      <div className="flex items-start fixed bg-white w-60">
        <div className="bg-indigo-50 rounded flex items-center flex-col justify-center p-1 rounded-full mr-2">
          {items.map((item, index) => (
            <div className="flex flex-col items-center justify-center" key={index}>
              <div
                className={index === current.item ? "text-sm bg-indigo-600 rounded-full w-5 h-5 text-center flex items-center justify-center text-white mb-4" : "text-sm bg-gray-700 rounded-full w-5 h-5 text-center flex items-center justify-center text-white mb-4"}>
                {index + 1}
              </div>
              {
                index === current.item && (
                  item.steps.map((step, stepIndex) => (
                    <div
                      className={(index === current.item && stepIndex === current.step) ? "w-2 h-2 rounded-full bg-indigo-600 mb-5" : "w-2 h-2 rounded-full  mb-5 bg-gray-400"}
                      key={stepIndex}></div>
                  ))
                )
              }
            </div>

          ))}

        </div>
        <div className="flex items-center flex-col justify-center">
          <div>
            {items.map((item, index) => (
              <div className="flex flex-col items-start p-1" key={index}>
                <div className="mb-1 hover:cursor-pointer" onClick={() => onStepChange({item: index, step: 0})}>
                  {item.name}
                </div>
                {index === current.item && (
                  item.steps.map((step, stepIndex) => (
                      <div key={stepIndex} onClick={() => onStepChange({item: index, step: stepIndex})}
                           className={(index === current.item && stepIndex === current.step) ? "pl-3 py-0.5 text-indigo-600 font-medium hover:cursor-pointer" : "pl-3 py-0.5 text-gray-500 hover:cursor-pointer"}>
                        {step.name}
                      </div>
                    ))
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="ml-64">
        {items[current.item].steps[current.step].component}
      </div>

    </div>
  );
}

export default AccountSideBar;