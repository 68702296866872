import React, {useEffect, useState} from 'react';
import Select from "../../@yb/components/select";
import {useDispatch, useSelector} from "react-redux";
import {getCountries, setSelectedCountry} from "../../redux/features/countrySlice";
import {createAccount} from "../../redux/features/account/accountSlice";
import {useNavigate} from "react-router-dom";

function CreateAccount() {
    const dispatch = useDispatch();
    const {countries, selectedCountry} = useSelector(state => state.country);
    const {loading} = useSelector(state => state.account);
    const [name, setName] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getCountries());
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        let data = {
            country_id: selectedCountry.id,
            name: name,
        }
        console.log(data);
        dispatch(createAccount(data)).then((e) => {
            if (e.type === createAccount.fulfilled.type) {
                navigate("/");
            }
        });
    };

    return (
        <div>
            <section className=" py-1">
                <div className="w-full lg:w-8/12 px-4 mx-auto mt-6">
                    <div
                        className="relative flex flex-col min-w-0 break-words w-full mb-6  rounded-lg border-0">

                        <div className="flex-auto px-4  py-10 pt-0">
                            <form onSubmit={handleSubmit}>
                                <h6 className="text-blueGray-400 text-sm mt-3 mb-10 font-bold uppercase">
                                    Create account
                                </h6>
                                <div className="flex flex-wrap">
                                    <div className="w-full lg:w-6/12">
                                        <div className="relative w-full mb-3">
                                            <label className="block leading-5 text-gray-500 text-sm font-bold mb-2"
                                                   htmlFor="grid-password">
                                                Nom
                                            </label>
                                            <input required type="text" onChange={(e) => setName(e.target.value)}
                                                   className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                   placeholder="Nom de la boutique"/>
                                        </div>
                                    </div>
                                    <div className="w-full lg:w-6/12 px-4">
                                        <div className="relative w-full mb-3">
                                            <label className="block leading-5 text-gray-500 text-sm font-bold mb-2"
                                                   htmlFor="grid-password">
                                                Country
                                            </label>
                                            <Select
                                                options={countries}
                                                selectedOption={selectedCountry}
                                                handelChange={(event) => {
                                                    console.log("parent", event);
                                                    dispatch(setSelectedCountry(event));
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <button type="submit"
                                        className="flex items-center rounded-lg bg-indigo-500 px-4 py-2 mt-4 text-white"
                                        disabled={loading || (!selectedCountry && !name)}>
                                    {loading ?
                                        <svg className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                                             xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10"
                                                    stroke="currentColor" strokeWidth="4"/>
                                            <path className="opacity-75" fill="currentColor"
                                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                                        </svg>
                                        :
                                        <span className="font-medium subpixel-antialiased">Create</span>
                                    }
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default CreateAccount;