import React from "react";
import { createRoot } from "react-dom/client";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import {persistor, store} from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { persistStore } from "redux-persist";
import { BrowserRouter } from "react-router-dom";
import "mapbox-gl/dist/mapbox-gl.css";
import { BrowserTracing } from "@sentry/tracing";
import * as Sentry from "@sentry/react";
import Router from "./router";
import '@tremor/react/dist/esm/tremor.css';


const container = document.getElementById("root");
const root = createRoot(container);

/*Sentry.init({
  dsn: "https://732cdf21124f4eb58ade30ea5877651b@o1160355.ingest.sentry.io/6621402",
  integrations: [new BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});*/

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router />
      </PersistGate>
    </Provider>
  </BrowserRouter>
);
reportWebVitals();
