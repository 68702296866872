import axios from "axios";
import notification from "../@yb/components/notification";

const createCommand = async (command) => {
  return axios.post(`${process.env.REACT_APP_STORE_URL}/command`, command, {
    headers: {
      'Authorization-Token': `Bearer ${localStorage.getItem('token')}`,
    }
  }).then(res => res.data).catch(err => {
    notification.error("Payment link creation failed");
    throw new Error(err);
  });
}

export default createCommand