import React, { useEffect, useState } from 'react';

function Overview() {
    return (
        <div className="h-full">
            <h3 className="mb-4 text-black font-bold text-3xl">Balance</h3>
        </div>
    );
}

export default Overview;