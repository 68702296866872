import { useState } from 'react';
import {
  AreaChart,
  Block,
  Card,
  Flex,
  Icon,
  Text,
  Title,
  Toggle,
  ToggleItem,
} from '@tremor/react';
import {InformationCircleIcon} from "@heroicons/react/20/solid";

export const performance = [
  {
    date: '2021-01-01', Sales: 900.73, Profit: 173, Customers: 73,
  },
  {
    date: '2021-01-02', Sales: 1000.74, Profit: 174.6, Customers: 74,
  },
  {
    date: '2021-03-13', Sales: 882, Profit: 682, Customers: 682,
  },
  {
    date: '2021-03-14', Sales: 900, Profit: 700, Customers: 700,
  },
  {
    date: '2021-03-15', Sales: 1000, Profit: 800, Customers: 800,
  },
  {
    date: '2021-03-16', Sales: 1100, Profit: 900, Customers: 900,
  },
  {
    date: '2021-03-17', Sales: 1200, Profit: 1000, Customers: 1000,
  },
  {
    date: '2021-03-18', Sales: 1300, Profit: 1100, Customers: 1100,
  },
  {
    date: '2021-03-19', Sales: 800, Profit: 1200, Customers: 1200,
  },
  {
    date: '2021-03-20', Sales: 1500, Profit: 1300, Customers: 1300,
  },
  {
    date: '2021-03-21', Sales: 1000, Profit: 1400, Customers: 1400,
  },
  {
    date: '2021-03-22', Sales: 1700, Profit: 1500, Customers: 1500,
  },
  {
    date: '2021-03-23', Sales: 1800, Profit: 1600, Customers: 1600,
  },
  {
    date: '2021-03-24', Sales: 1900, Profit: 1700, Customers: 1700,
  }
];

// Basic formatters for the chart values
const dollarFormatter = (
  value,
) => (`$ ${Intl.NumberFormat('us').format(value).toString()}`);

const numberFormatter = (
  value,
) => (`${Intl.NumberFormat('us').format(value).toString()}`);

export default function ChartView() {
  const [selectedKpi, setSelectedKpi] = useState('Sales');

  // map formatters by selectedKpi
  const formatters = {
    Sales: dollarFormatter,
    Profit: dollarFormatter,
    Customers: numberFormatter,
  };

  return (
    <Card>
      <div className="md:flex justify-between">
        <Block>
          <Flex justifyContent="justify-start" spaceX="space-x-0.5" alignItems="items-center">
            <Title> Performance </Title>
            <Icon
              icon={ InformationCircleIcon }
              variant="simple"
              tooltip="Shows day-over-day (%) changes of past performance"
            />
          </Flex>
          <Text> Daily increase or decrease per domain </Text>
        </Block>
        <div className="mt-6 md:mt-0">
          <Toggle
            color="zinc"
            defaultValue={ selectedKpi }
            handleSelect={ (value) => setSelectedKpi(value) }
          >
            <ToggleItem value="Sales" text="Sales" />
            <ToggleItem value="Profit" text="Profit" />
            <ToggleItem value="Customers" text="Customers" />
          </Toggle>
        </div>
      </div>
      <AreaChart
        data={ performance }
        dataKey="date"
        categories={ [selectedKpi] }
        colors={ ['blue'] }

        showLegend={ false }
        valueFormatter={ formatters[selectedKpi] }
        marginTop="mt-8"
      />
    </Card>
  );
}