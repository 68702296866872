import React from "react";
import { Badge } from "@tremor/react";



const InvoiceStatus = ({ status }) => {
  return (
    <>
      {status === "draft" ? (
        <Badge
          text={status}
          color="gray"
          size="sm"
          icon={undefined}
          tooltip=""
          marginTop="mt-0" />
      ) : status === "canceled" ? (
        <Badge
          text={status}
          color="red"
          size="sm"
          icon={undefined}
          tooltip=""
          marginTop="mt-0" />
      ) : status === "pending" ? (
        <Badge
          text={status}
          color="indigo"
          size="sm"
          icon={undefined}
          tooltip=""
          marginTop="mt-0" />
      ) : status === "paid" ? (
        <Badge
          text={status}
          color="green"
          size="sm"
          icon={undefined}
          tooltip=""
          marginTop="mt-0" />
      ) : (
        <Badge
          text={status}
          color="gray"
          size="sm"
          icon={undefined}
          tooltip=""
          marginTop="mt-0" />
      )}
    </>
  );
};

export default InvoiceStatus;
