import { createSlice } from "@reduxjs/toolkit";

const invoiceModeSlice = createSlice({
  name: "modeInvoice",
  initialState: {
    selectedMode: "ONE_TIME",
    limitDate: null,
  },
  reducers: {
    setSelectedMode: (state, action) => {
      state.selectedMode = action.payload;
    },
    setRemoveMode: (state, action) => {
      state.selectedMode = null;
    },
    setLimiteDate: (state, action) => {
      state.limitDate = action.payload;
    },
  },
});
export const { setSelectedMode, setLimiteDate, setRemoveMode } =
  invoiceModeSlice.actions;
export default invoiceModeSlice.reducer;
