import React from 'react';
import {
  Card,
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
  Text,
  Title,
  Badge, Bold, Divider
} from "@tremor/react";

function YTable({
                  data, columns, pagination, onActionClick = () => {
  }, emptyMessage = 'No data found'
                }) {

  const temp = () => {
    return (
      <div>
        <div>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableHeaderCell key={index}>
                    <Bold>{column.header}</Bold>
                  </TableHeaderCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell key={index}
                               //textAlignment={column.accessor === 'status' ? 'text-right' : 'text-left'}
                    >
                      {column.render ? column.render(row[column.accessor]) :
                        <Text>{row[column.accessor]}</Text> ?? emptyMessage}
                    </TableCell>
                  ))}
                  <TableCell textAlignment='text-right'>
                    {onActionClick(row)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        {pagination && (
          <div className="bg-white">
            <hr className="my-3"/>

            {pagination}
          </div>
        )}
      </div>

    )
  }

  return (
    <div className="w-full mx-auto relative">
      {temp()}
      {/*<div className="inline-block min-w-full py-2 align-middle">
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
          <table className="m-0 min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
            <tr>
              {columns.map((column) => (
                <th
                  key={column.accessor}
                  scope="col" className="py-3 pr-3 px-4 text-left text-sm font-semibold text-gray-900"
                >
                  {column.header}
                </th>
              ))}
              <th></th>
            </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">

            {data.map((row, index) => (
              <tr key={index}>
                {columns.map((column) => (
                  <td key={column.accessor} className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-left">
                    {column.render ? column.render(row[column.accessor]) : row[column.accessor] ?? emptyMessage}
                  </td>
                ))}
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-semibold text-gray-900">
                  {onActionClick(row)}
                </td>
              </tr>
            ))}
            </tbody>
          </table>
          {pagination && (
            <div className="bg-white px-5 py-1">
              {pagination}
            </div>
          )}
        </div>
      </div>*/}
    </div>
  );
}

export default YTable;