import { createSlice } from "@reduxjs/toolkit";

const customerInputSlice = createSlice({
  name: "customerInputSlice",
  initialState: {
    customer: null,
    showAddCustomerInput: false,
    showModalCustomer: false,
    cartProducts: [],
    loading: false,
    error: null,
  },
  reducers: {
    setCustomer: (state, action) => {
      state.customer = action.payload;
    },
    resetCustmer: (state) => {
      state.customer = null;
    },
    setshowAddCustomerInput: (state, action) => {
      state.showAddCustomerInput = action.payload;
    },
    setShowModalCustomer: (state, action) => {
      state.showModalCustomer = action.payload;
    },
    resetCustomerInput: (state) => {
      state.customer = null;
      state.showAddCustomerInput = false;
      state.showModalCustomer = false;
    },
  },
  extraReducers: {},
});

export const {
  setCustomer,
  setShowModalCustomer,
  setshowAddCustomerInput,
  resetCustomerInput,
  resetCustmer,
} = customerInputSlice.actions;
export default customerInputSlice.reducer;
