import React from 'react';
import Login from "../../../components/auth/login";

function SystemLogin() {
    return (
        <div>
            <Login/>
        </div>
    );
}

export default SystemLogin;