import React, {forwardRef, useEffect, useState} from "react";
import "react-datepicker/dist/react-datepicker.css";

import {useDispatch, useSelector} from "react-redux";
import {getCustomers} from "../../redux/features/customer/customerSlice";

import {getProducts} from "../../redux/features/product/productSlice";
import Topbard from "../../components/invoices/Topbard";
import {setLimiteDate} from "../../redux/features/invoices/invoiceModeSlice";

import ProductInput from "../../@yb/components/invoices/ProductInput";
import CustomerInput from "../../@yb/components/invoices/CustomerInput";

import CheckoutPreview from "../../components/payments/payment-link/checkoutPreview";
import DatePicker from "react-datepicker";
import {format} from "date-fns";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/20/solid";
import {Button} from "@tremor/react";
import {HiArrowSmLeft, HiArrowSmRight} from "react-icons/hi";

export const PickerCustomRender = (
  {
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }) => {
  return (
    <div className="flex items-center justify-between px-2 py-2">
        <span className="text-lg text-gray-700">
            {format(date, "MMMM yyyy")}
        </span>

      <div className="space-x-2">
        <button
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
          type="button"
          className={` ${
            prevMonthButtonDisabled &&
            "cursor-not-allowed opacity-50"
          } inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500
            `}
        >
          <ChevronLeftIcon className="w-5 h-5 text-gray-600"/>
        </button>

        <button
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
          type="button"
          className={`${
            nextMonthButtonDisabled &&
            "cursor-not-allowed opacity-50"
          } inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500
             `}
        >
          <ChevronRightIcon className="w-5 h-5 text-gray-600"/>
        </button>
      </div>
    </div>
  );
};

const InvoiceDueDate = ({onNext, initDate = new Date(), width = 'w-1/2 mt-32', showNavigation= true}) => {
  const [deadline, setDeadLine] = useState(initDate);

  const dispatch = useDispatch();


  const setDeadline = (date) => {
    setDeadLine(date);
    dispatch(setLimiteDate(date));
  };

  useEffect(() => {
    dispatch(setLimiteDate(deadline));
  });

  return (
    <div className={`${width} flex flex-col mx-auto `}>
      <div className="mb-3 flex flex-col text-left">
        <span className="font-bold text-2xl">Deadline</span>

        <DatePicker
          selected={deadline}
          onChange={(date) => setDeadline(date)}
          className="w-full border-t-0 border-l-0 border-r-0 border-b-gray-300 focus:ring-0 focus:ring-offset-0 pl-0 mt-10 text-2xl focus:border-b-gray-300 focus:border-b-2 text-gray-300"
          renderCustomHeader={PickerCustomRender}
        />
      </div>

      {showNavigation && (
        <div className="flex justify-between mt-20">
          <Button
            size="sm"
            importance="primary"
            text="Précédent"
            icon={HiArrowSmLeft}
            onClick={() => onNext(2)}
          />

        </div>
      )}

    </div>
  );
};
export default InvoiceDueDate;
