import React from 'react';
import {useSelector} from "react-redux";


const Constants = {
  getPaymentUrl:  (selectedAccount) => {
    return selectedAccount?.is_live ? process.env.REACT_APP_PROD_PAYMENT_URL : process.env.REACT_APP_TEST_PAYMENT_URL;
  }
}


export default Constants;