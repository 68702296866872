import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import notification from "../../../@yb/components/notification";
import Constants from "../../../@yb/utils/constants";

export const paymentTypes = [
  {
    name: "Product or subscription",
    description: "Best for ecommerce or software-as-a-service.",
    type: 1,
  },
  {
    name: "Customer choose what to pay",
    description: "Best for tipping, donations, or pay-what-you-want.",
    type: 2,
  },
];

export const createPaymentLink = createAsyncThunk(
  "payment/createPaymentLink",
  async (data) => {

    return axios
      .post(`${Constants.getPaymentUrl(data.selectedAccount)}/api/v1/payment-links`, data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }
);

export const getPaymentLink = createAsyncThunk(
  "payment/getPaymentLink",
  async (data) => {
    return axios
      .get(
        `${Constants.getPaymentUrl(data.selectedAccount)}/api/v1/payment-links/account/${data.account}?page=${data.page}&perPage=${data.postsPerPage}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }
);

const createPaymentLinkSlice = createSlice({
  name: "createPaymentLink",
  initialState: {
    cartProducts: [],
    paymentLink: [],
    showModal: false,
    showAddProductInput: false,
    collectUserDetails: true,
    activeTab: ".0",
    confirmPageOption: 1,
    showCustomMessage: false,
    customMessage: "",
    selectedType: paymentTypes[0],
    title: "",
    description: "",
    image: "",
    isLimited: false,
    limits: {
      min: 0,
      max: 0,
    },
    callToAction: "Pay",
    callbackUrl: "",
    loading: false,
  },
  reducers: {
    setCartProducts: (state, action) => {
      state.cartProducts = action.payload;
    },
    addCartProducts: (state, action) => {
      state.cartProducts.push(action.payload);
    },
    removeCartProduct: (state, action) => {
      state.cartProducts = state.cartProducts.filter(
        (product) => product.product.uuid !== action.payload
      );
      state.showAddProductInput = false;
    },
    updateCartProductQuantity: (state, action) => {
      state.cartProducts = state.cartProducts.map((product) => {
        if (product.product.uuid === action.payload.uuid) {
          return {
            ...product,
            quantity: action.payload.quantity,
          };
        }
        return product;
      });
    },
    setShowModal: (state, action) => {
      state.showModal = action.payload;
    },
    setShowAddProductInput: (state, action) => {
      state.showAddProductInput = action.payload;
    },
    setCollectUserDetails: (state, action) => {
      state.collectUserDetails = action.payload;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setConfirmPageOption: (state, action) => {
      state.confirmPageOption = action.payload;
    },
    setShowCustomMessage: (state, action) => {
      state.showCustomMessage = action.payload;
    },
    setCustomMessageText: (state, action) => {
      state.customMessage = action.payload;
    },
    setSelectedType: (state, action) => {
      state.selectedType = action.payload;
    },
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    setDescription: (state, action) => {
      state.description = action.payload;
    },
    setImage: (state, action) => {
      state.image = action.payload;
    },
    setIsLimited: (state, action) => {
      state.isLimited = action.payload;
    },
    setLimitMin: (state, action) => {
      state.limits.min = action.payload;
    },
    setLimitMax: (state, action) => {
      state.limits.max = action.payload;
    },
    setCallToAction: (state, action) => {
      state.callToAction = action.payload;
    },
    setCallbackUrl: (state, action) => {
      state.callbackUrl = action.payload;
    },
    resetCreatePaymentLink: (state) => {
      state.cartProducts = [];
      state.showModal = false;
      state.showModalCustomer = false;
      state.showAddProductInput = false;
      state.collectUserDetails = true;
      state.activeTab = ".0";
      state.confirmOption = 1;
      state.showCustomMessage = false;
      state.customMessage = "";
      state.selectedType = paymentTypes[0];
      state.title = "";
      state.description = "";
      state.image = "";
      state.isLimited = false;
      state.limits = {
        min: 0,
        max: 0,
      };
    },
  },
  extraReducers: {
    [createPaymentLink.pending]: (state, action) => {
      state.loading = true;
    },
    [createPaymentLink.fulfilled]: (state, action) => {
      state.loading = false;
      resetCreatePaymentLink();
    },
    [createPaymentLink.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      notification.error("Error creating payment link");
    },
    [getPaymentLink.pending]: (state, action) => {
      state.loading = true;
    },
    [getPaymentLink.fulfilled]: (state, action) => {
      state.loading = false;
      state.paymentLink = action.payload;
    },
    [getPaymentLink.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      notification.error("Error creating payment link");
    },
  },
});

export const {
  setCartProducts,
  removeCartProduct,
  updateCartProductQuantity,
  setShowModal,
  setShowAddProductInput,
  setCollectUserDetails,
  setActiveTab,
  setConfirmPageOption,
  setShowCustomMessage,
  setCustomMessageText,
  resetCreatePaymentLink,
  setSelectedType,
  setTitle,
  setDescription,
  setImage,
  setIsLimited,
  setLimitMin,
  setCallToAction,
  setLimitMax,
  setCallbackUrl,
  addCartProducts
} = createPaymentLinkSlice.actions;
export default createPaymentLinkSlice.reducer;
