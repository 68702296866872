import axios from "axios";
import notification from "../@yb/components/notification";

async function getIndustries() {
  return await axios.get(`${process.env.REACT_APP_AUTH_URL}/v1/industry-category`)
    .then(response => {
      return response.data;
    }).catch(error => {
      console.log(error);
      notification.error(error.message);
      throw error;
    })
    ;
}


export default getIndustries;