import {toast} from "react-hot-toast";

export default {
    success(message) {
        toast.success(message, {
            position: "top-right",
            duration: 4000,
        });
    },
    info(message) {
        toast.custom(message, {
            position: "top-right",
            duration: 4000,
        });
    },
    error(message) {
        toast.error(message, {
            position: "top-right",
            duration: 4000,
        });
    },
    promise(func, loadingMessage, successMessage, errorMessage) {
        toast.promise(
            func,
            {
                loading: loadingMessage,
                success: (res) => successMessage,
                error: (err) => errorMessage,
            },
            {
                style: {
                    minWidth: '250px',
                },
                position: "top-right",
            }
        );
    },
};
