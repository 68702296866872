import React from "react";
import Invoice from "../invoices";
import { Tab, Tabs } from "../../@yb/components/Tabs/Tabs";
import { useDispatch, useSelector } from "react-redux";
import { setInvoiceActive } from "../../redux/features/invoices/crud/invoiceSlice";
import PaymentList from "../../components/payments/payment/payment-list";
import PaymentLink from "../../components/payments/payment-link";
import Transfer from "../../components/payments/transfer";

function Payments() {
  const { active } = useSelector((state) => state.invoice);
  const dispatch = useDispatch();

  return (
    <>
      <Tabs
        title="Payments"
        active={active}
        onChange={(e) => {
          dispatch(setInvoiceActive(e));
        }}
      >
        <Tab title="Tous les paiements">
          <PaymentList />
        </Tab>
         <Tab title="Transferts">
           <Transfer />
         </Tab>
       {/* <Tab title="Invoices">
          <Invoice />
        </Tab>*/}
        <Tab title="Lien de paiements">
          <PaymentLink />
        </Tab>
      </Tabs>
    </>
  );
}

export default Payments;
