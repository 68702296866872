import {createSlice} from '@reduxjs/toolkit'
const userSlice = createSlice({
        name: 'user',
        initialState: {
            user: {},
            isAuthenticated: false,
        },
        reducers: {
            setUser: (state, action) => {
                state.user = action.payload
            },
            setIsAuthenticated: (state, action) => {
                state.isAuthenticated = action.payload
            },
            toggleEnvironment: (state, action) => {
                state.user.is_live = action.payload
            },
            logout: (state, action) => {
                state.user = {}
                state.isAuthenticated = false
                localStorage.clear();
            },
        }
    }
)

export const {setUser, setIsAuthenticated, toggleEnvironment, logout} = userSlice.actions
export default userSlice.reducer