import React, { useState } from "react";
import YabetooLogo from "../../../@yb/components/yabetooLogo";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setIsAuthenticated, setUser } from "../../../redux/user";
import { toast } from "react-hot-toast";

import notifier from "../../../@yb/components/notification";
import { Link, useNavigate } from "react-router-dom";

const Register = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const register = async (event) => {
    event.preventDefault();
    setLoading(true);
    axios
      .post(
        process.env.REACT_APP_AUTH_URL + "/register",
        {
          first_name: firstName,
          last_name: lastName,
          email: email,
          password: password,
        },
        {
          withCredentials: false,
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        console.log(res);
        notifier.success("Registration successful");
        navigate("/auth/login");
      })
      .catch((err) => {
        console.log(err);
        setError(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="h-screen max-w-xl mx-auto flex flex-col space-y-10">
      <div className="w-72 pt-10 mx-auto">
        <YabetooLogo />
      </div>
      <div className="bg-white p-12 shadow rounded p-5">
        <h1 className="text-3xl font-medium">S’identifier</h1>
        <p className="text-sm">
          Tenez-vous au courant des évolutions de votre monde professionnel
        </p>
        <form onSubmit={register} className="space-y-5 mt-5">
          <div className="flex flex-wrap -mx-3">
            <div className="w-full md:w-1/2 px-3">
              <label className="block text-sm font-medium text-gray-700">
                Nom{" "}
              </label>
              <input
                required
                type="text"
                onChange={(event) => setFirstName(event.target.value)}
                className="w-full h-12 border border-gray-300 rounded px-3"
                placeholder="Nom"
              />
            </div>
            <div className="w-full md:w-1/2 px-3">
              <label className="block text-sm font-medium text-gray-700">
                Prenom{" "}
              </label>
              <input
                required
                type="text"
                onChange={(event) => setLastName(event.target.value)}
                className="w-full h-12 border border-gray-300 rounded px-3"
                placeholder="Prenom"
              />
            </div>
          </div>

          <label className="block text-sm font-medium text-gray-700">
            Email{" "}
          </label>
          <input
            required
            type="email"
            onChange={(event) => setEmail(event.target.value)}
            className="w-full h-12 border border-gray-300 rounded px-3"
            placeholder="E-mail"
          />
          <label className="block text-sm font-medium text-gray-700">
            Mot de passe{" "}
          </label>
          <input
            required
            type="password"
            onChange={(event) => setPassword(event.target.value)}
            className="w-full h-12 border border-gray-300 rounded px-3"
            placeholder="Mot de passe"
          />

          <button
            type="submit"
            className="flex items-center justify-center text-center w-full bg-blue-700 rounded text-white py-3 font-medium"
          >
            {loading ? (
              <svg
                className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                />
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
            ) : (
              <span className="font-medium subpixel-antialiased">
                Continuer
              </span>
            )}
          </button>
        </form>

        <div className="flex items-center justify-center space-x-2 my-5">
          <span className="h-px w-16 bg-gray-200"></span>
          <span className="text-gray-300 font-normal">ou</span>
          <span className="h-px w-16 bg-gray-200"></span>
        </div>

        <div className="flex w-full justify-center">
          <p>
            {" "}
            Déja un compte Yabetoo ?{" "}
            <Link to="/auth/login">
              {" "}
              <span className="text-blue-700 font-medium">Se connecter</span>
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Register;
