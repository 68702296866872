import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import notification from "../../../../@yb/components/notification";

export const updatedInvoice = createAsyncThunk(
  "invoice/updateinvoice",
  async ({ updateInvoiceDate, invoiceID }) => {
    return axios
      .put(
        `${process.env.REACT_APP_YABETOO_INVOICE}/invoice/${invoiceID}`,
        updateInvoiceDate,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data)
      .catch((e) => e.response);
  }
);

const updateInvoiceSlice = createSlice({
  name: "updateInvoice",
  initialState: {
    invoice: null,
    loading: false,
    error: null,
  },
  extraReducers: {
    [updatedInvoice.pending]: (state, action) => {
      state.loading = true;
    },
    [updatedInvoice.fulfilled]: (state, action) => {
      state.loading = false;
      state.invoice = action.payload.data;
      notification.success("invoice updated successfully");
    },
    [updatedInvoice.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      notification.error(action?.payload?.message);
    },
  },
});

export default updateInvoiceSlice.reducer;
