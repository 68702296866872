import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import notification from "../../../@yb/components/notification";

export const createProducts = createAsyncThunk(
  "Addproduct/createProducts",
  async ({ productData }) => {
    return axios
      .post(`${process.env.REACT_APP_INVOICE_API_URL}/product`, productData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => res.data)
      .catch((e) => e.response);
  }
);

const addProductSlice = createSlice({
  name: "Addproduct",
  initialState: {
    product: [],
    isloading: false,
    error: null,
  },

  extraReducers: {
    [createProducts.pending]: (state, action) => {
      state.isloading = true;
    },
    [createProducts.fulfilled]: (state, action) => {
      state.isloading = false;
      state.product = action.payload.data;
      notification.success(action?.payload?.message);
    },
    [createProducts.rejected]: (state, action) => {
      state.isloading = false;
      state.error = action.payload;
      notification.error(action?.payload?.message);
    },
  },
});
export default addProductSlice.reducer;
