import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";
import notification from "../../../@yb/components/notification";

export const createAccount = createAsyncThunk('account/create', async (data) => {
    return axios.post(`${process.env.REACT_APP_AUTH_URL}/v1/account/create`, data, {
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("token")
        },
    }).then(res => res.data);
})

const initialState = {
    accounts: [],
    selectedAccount: {},
    loading: false,
    error: null,
}

const accountSlice = createSlice({
        name: 'account',
        initialState: initialState,
        reducers: {
            setAccounts: (state, action) => {
                state.accounts = action.payload
            },
            setSelectedAccount: (state, action) => {
                console.log('action', action.payload.name);
                state.selectedAccount = action.payload
            },
            addAccount: (state, action) => {
                state.accounts.push(action.payload)
            }
        },
        extraReducers: {
            [createAccount.pending]: (state, action) => {
                state.loading = true
            },
            [createAccount.fulfilled]: (state, action) => {
                state.accounts.push(action.payload)
                state.selectedAccount = action.payload
                state.loading = false
                notification.success('Account created successfully');
            },
            [createAccount.rejected]: (state, action) => {
                state.loading = false
                state.error = action.payload
                notification.error('Store creation failed');
            },
        },
    }
)

export const {setAccounts, setSelectedAccount, addAccount} = accountSlice.actions
export default accountSlice.reducer