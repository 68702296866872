import React from 'react';
import 'moment/locale/fr';
import {Tab, Tabs} from "../../../@yb/components/Tabs/Tabs";
import Payments from "./payments"

function PaymentList() {
    return (
        <div className="h-full">
            <div className="flex flex-row items-center mb-4">
                <h3 className="flex-1 text-black font-bold text-3xl">Paiements</h3>
            </div>
            <Tabs direction="horizontal">
                <Tab title="Tous">
                    <Payments></Payments>
                </Tab>
                <Tab title="Réussi">
                    <Payments valueFromParent = {"succeeded"}></Payments>
                </Tab>
                <Tab title="En attente">
                    <Payments valueFromParent = {"incomplete"}></Payments>
                </Tab>
                <Tab title="Échouer">
                    <Payments valueFromParent = {"failed"}></Payments>
                </Tab>
            </Tabs>
        </div>
    );
}

export default PaymentList;