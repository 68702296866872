import React from 'react';
import InputField from "../../@yb/components/Input/InputField";
import {NumericFormat, PatternFormat} from 'react-number-format';
import "../../@yb/components/PhoneInput/style.css";
import PhoneInputField from "../../@yb/components/PhoneInput";
import {useDispatch, useSelector} from "react-redux";
import {
  setAddress,
  setBirthDate, setCity, setCurrentStep,
  setEmail,
  setFirstName,
  setLastName, setPhone, setZipCode
} from "../../redux/features/account/activateAcountSlice";
import formatDate from "../../@yb/utils/formatDate";

function PersonalInformation() {

  //const [firstName, setFirstName] = useState("");


  const dispatch = useDispatch();
  const {
    firstName,
    lastName,
    email,
    phone,
    address,
    birthDate,
    city,
    zipCode
  } = useSelector((state) => state.activateAccount);


  const next = () => {
    const data = {firstName, lastName, email, phone, birthDate, address, city, zipCode};
    dispatch(setCurrentStep({item : 0, step: 2}))
  }


  return (
    <div>
      <p className="text-3xl">Verify your personal details</p>
      <span>Yabetoo collects this information to verify your identity and keep your account safe.</span>

      <div>
        <div className="mt-2">
          <InputField value={firstName} onChange={(e) => dispatch(setFirstName(e))} placeholder="Firstname"
                      label="Legal name of person"/>
        </div>
        <div className="mt-2">
          <InputField value={lastName} onChange={(e) => dispatch(setLastName(e))} placeholder="Lastname"/>
        </div>
      </div>

      <div className="mt-5">
        <InputField value={email} onChange={(e) => dispatch(setEmail(e))} placeholder="Email" label="Email address"/>
      </div>

      <div className="mt-5">
        <label className={`text-sm block mb-2 font-medium text-gray-700 pt-5`} htmlFor="app-input-field">Birth
          day</label>
        <PatternFormat
          defaultValue={birthDate} onValueChange={(e) => dispatch(setBirthDate(e.value))}
          className="h-10 w-40 border border-gray-300 rounded-lg px-3 py-2 outline-0 border-gray-300"
          format="##/##/####" mask="_" placeholder="DD / MM / YYYY"
        />
      </div>

      <div className="mt-5">
        <InputField value={address} onChange={(e) => dispatch(setAddress(e))} placeholder="Address" label="Home address"/>
        <div className="mt-2">
          <NumericFormat
            defaultValue={zipCode} onValueChange={(e) => dispatch(setZipCode(e.value))}
            className="h-10 w-full border border-gray-300 rounded-lg px-3 py-2 outline-0 border-gray-300"
            placeholder="Postal code"
          />
        </div>
        <div className="mt-2">
          <InputField value={city} onChange={(e) => dispatch(setCity(e))} placeholder="City"/>
        </div>
      </div>

      <div className="mt-5">
        <PhoneInputField defaultValue={phone} label="Phone number" onChange={(e) => dispatch(setPhone(e))}/>
      </div>

      <div className="mt-5">
        <button onClick={next} type='button'
                className="flex items-center justify-center text-center w-full bg-blue-700 rounded-lg text-white py-2 font-medium">
          <span className="font-medium subpixel-antialiased">Continuer</span>
        </button>
      </div>


    </div>
  );
}

export default PersonalInformation;