import React, {useState, useEffect, Fragment, useMemo} from 'react';
import { NumericFormat } from 'react-number-format';
import {Link} from "react-router-dom";
import {Tab, Tabs} from "../../@yb/components/Tabs/Tabs";
import {useDispatch, useSelector} from "react-redux";
import {getProducts, setProducts} from "../../redux/features/product/productSlice";
import ProductEmpty from "./productEmpty";
import Table from "../../@yb/components/Table/Table";


const ProductOverview = () => {
  //const [products, setProducts] = useState([]);
  const {products} = useSelector(state => state.product);
  const {selectedAccount} = useSelector(state => state.account);
  const dispatch = useDispatch();


  useEffect(() => {
    fetchProducts()
  }, [selectedAccount]);

  const fetchProducts = async () => {
    dispatch(getProducts(selectedAccount.id));
  }

  const columns = useMemo(() => [
    {
      header: 'Name',
      accessor: 'name',
    },
    {
      header: 'Price',
      accessor: 'price',
      render: (price) => <NumericFormat value={price} displayType={'text'} thousandSeparator={true} prefix={'$ '}/>
    },
    {
      header: 'Reference',
      accessor: 'reference',
    }
  ], []);

  return (<div className="h-full py-2 flex flex-col">
    <div className="flex justify-between items-center">
      <h1 className="mb-6 text-black font-bold text-4xl">Products</h1>

      <Link to="/product/create"
        className="px-3 py-1 transition-colors duration-700 transform bg-indigo-500 hover:bg-blue-400 text-gray-100 rounded-lg focus:border-4 border-indigo-300">Ajouter
        un produit
      </Link>

    </div>

    <div className="w-full">
      <Tabs direction="horizontal" >
        <Tab title="Disponibles">
         <div className="mt-5"> { products.length > 0 ? <Table data={products} columns={columns}/> : <ProductEmpty />}</div>
        </Tab>
        <Tab title="En attente">
          <ProductEmpty />
        </Tab>
      </Tabs>
    </div>

  </div>);
};


export default ProductOverview;